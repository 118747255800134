<template>
    <section>
        <div style="background-color:white">
            <div class="container" style="padding:1.2rem 2rem">
                <!--                <div class="card">-->
                <header style="display:block;">
                    <div class="level">
                        <div class="level-left is-hidden-mobile">
                            <div class="level-item ">
                                <figure class="image is-64x64" style="padding:5px">
                                    <img src="../assets/grin_logo.png" alt="Image" v-if="coin === 'GRIN'">
                                    <img src="../assets/mwc_logo.png" alt="Image" v-if="coin === 'MWC'">
                                    <img src="../assets/usdt_logo.png" alt="Image" v-if="coin === 'USDT'">
                                </figure>
                            </div>
                            <div class="level-item">
                                <h1 class="subtitle is-3 ">
                                    {{coin}}
                                </h1>
                            </div>
                        </div>
                        <div class="level-right">
                            <div style="float: left" class="is-inline is-hidden-desktop">
                                <div>
                                    <figure class="image is-32x32" style="padding:5px;float: left;">
                                        <img src="../assets/grin_logo.png" alt="Image" v-if="coin === 'GRIN'">
                                        <img src="../assets/mwc_logo.png" alt="Image" v-if="coin === 'MWC'">
                                        <img src="../assets/usdt_logo.png" alt="Image" v-if="coin === 'USDT'">
                                    </figure>
                                    <strong style="float: right;padding-top: 0.2rem"> {{$t("msg.withdraw.withdraw")}} </strong>
                                </div>
                            </div>
                            <div class="select" style="float: right;">
                                <select v-model="coin">
                                    <option value="GRIN" selected>GRIN</option>
                                    <option value="MWC">MWC</option>
                                    <option value="USDT">USDT</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                </header>
                <div class="card" style="padding: 1.25rem">

                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">{{$t("msg.withdraw.method")}}</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control">
                                    <div class="select">
                                        <select v-model="method" @change="changeMethod">
                                            <option value="manual" v-if="coin === 'GRIN' || coin === 'MWC'">{{$t("msg.withdraw.manual")}}</option>
                                            <option value="http" v-if="coin === 'GRIN' || coin === 'MWC'">http</option>
                                            <option value="tor" v-if="coin === 'GRIN'">tor</option>
                                            <option value="mwcmqs" v-if="coin === 'MWC'">MWCMQS</option>
                                            <option value="trc20" v-if="coin === 'USDT'">TRC20</option>
                                            <!--                                                <option value="manual">手动</option>-->
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-label is-normal" v-if="method !== 'manual'">
                            <label class="label">{{$t("msg.withdraw.address")}}</label>
                        </div>
                        <div class="field-body" v-if="address && method !== 'manual'">
                            <div class="field">
                                <div class="control">
                                    <p class="help is-size-6 has-text-weight-semibold"
                                       style="word-break: break-all;word-wrap: break-word">
                                        {{address}}
                                    </p>
                                    <p class="help is-size-6 is-danger has-text-weight-semibold"
                                       style="word-break: break-all;word-wrap: break-word" v-if="!addressValid">
                                        {{$t("msg.withdraw.addressNeedActivate")}}
                                    </p>
                                    <p class="help is-size-6 is-danger has-text-weight-semibold"
                                       style="word-break: break-all;word-wrap: break-word" v-if="addressValid">
                                      {{$t("msg.withdraw.addressNeedOnline")}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="field-body" v-else>
                            <div class="field">
                                <div class="control">
                                    <input class="input" type="text" :placeholder="this.$t('msg.withdraw.hintTOR')"
                                           v-model="addressToBind" style="width:56.5%" v-if="method === 'tor'">
                                    <input class="input" type="text" :placeholder="this.$t('msg.withdraw.hintHTTP')"
                                           v-model="addressToBind" style="width:56.5%" v-if="method === 'http'">
                                    <input class="input" type="text" :placeholder="this.$t('msg.withdraw.hintMWCMQS')"
                                           v-model="addressToBind" style="width:56.5%" v-if="method === 'mwcmqs'">
                                    <input class="input" type="text" :placeholder="this.$t('msg.withdraw.hintUSDTTRC20')"
                                           v-model="addressToBind" style="width:56.5%" v-if="method === 'trc20'">
                                </div>
                                <div v-if="method === 'tor'">
                                    <br>
                                    <label class="label">Memo:</label>
                                    <div class="control">
                                        <input class="input" type="number" :placeholder="this.$t('msg.withdraw.hintMemo')" v-model="memo"
                                               style="width:56.5%;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-label">
                            <!-- Left empty for spacing -->
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control">
                                    <button class="button" @click="bindAddress" v-if="!address && method !== 'manual'">
                                        {{$t("msg.withdraw.bind")}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal" v-if="address && method !== 'manual'">
                        <div class="field-label">
                            <!-- Left empty for spacing -->
                        </div>

                        <div class="field-body">

                            <div class="field">
                                <div class="control">
                                    <button class="button" @click="resetAddress">
                                        {{$t("msg.withdraw.resetAddress")}}
                                    </button>
                                    <button class="button" @click="syncAddress">
                                      {{$t("msg.withdraw.syncAddress")}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>

                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">{{$t("msg.withdraw.balance")}}</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control">
                                    <p class="help has-text-weight-semibold is-size-6 is-family-sans-serif">
                                        {{balance}} {{coin}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">{{$t("msg.withdraw.withdrawQuantity")}}</label>
                        </div>
                        <div class="field-body">

                            <div class="field">
                                <div class="control">
                                    <input class="input" @keydown="handleAmount2" type="text" v-model.number=amount>
                                </div>
                                <p class="help">{{$t("msg.withdraw.minWithdraw")}} {{min}} {{coin}} ; {{$t("msg.withdraw.withdrawFee")}} {{fee}} {{coin}}; {{$t("msg.withdraw.maxAmount")}} {{maxAmount}} {{coin}}</p>
                            </div>

                            <div class="field">
                                <div class="control">
                                    <button class="button" @click="withdrawAll">
                                        {{$t("msg.withdraw.withdrawAll")}}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="field is-horizontal">
                        <div class="field-label is-normal">
                            <label class="label">{{$t("msg.withdraw.googleAuth")}}</label>
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control">
                                    <input class="input"  type="text" style="width: 56%" v-model=googleAuthCode>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>

                    <div class="field is-horizontal">
                        <div class="field-label">
                            <!-- Left empty for spacing -->
                        </div>
                        <div class="field-body">
                            <div class="field">
                                <div class="control">
                                    <button class="button" @click="withdraw" v-if='canWithdraw'>
                                        {{$t("msg.withdraw.withdraw")}}
                                    </button>
                                    <button class="button" v-else disabled>
                                        {{$t("msg.withdraw.withdraw")}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>

                </div>
            </div>
            <!--            </div>-->
            <div class="container" style="padding:1.2rem 2rem">
                <div class="level">
                    <div class="level-left">
                        <h1 class="title is-4">
                            {{$t("msg.withdraw.transactions")}}
                        </h1>
                    </div>
                    <div class="level-right">
                    </div>
                </div>
            </div>
            <div class="container" style="padding: 1.2rem 2rem">
                <table class="table is-fullwidth is-striped is-hoverable is-sortable is-size-7-mobile" v-if="txs">
                    <thead>
                    <tr>
                        <th>{{$t("msg.withdraw.data")}}</th>
                        <th>{{$t("msg.withdraw.method")}}</th>
                        <th >{{$t("msg.withdraw.kernel")}}</th>
                        <th >{{$t("msg.withdraw.id")}}</th>
                        <th>{{$t("msg.withdraw.amount")}}</th>
                        <th>{{$t("msg.withdraw.status")}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(tx, index) in txs" :key='index'>
                        <td>{{tx.CreatedAt}}</td>
                        <td>{{tx.Method}}</td>
<!--                        <td class="is-hidden-mobile"><a :href="kernelLink(tx.KernelExcess)">{{shortString(tx.KernelExcess,4)}}</a></td>-->
                      <td @click="copySlateID(index+100, tx.KernelExcess)" >
                            <span :data-tooltip="tx.KernelExcess" v-if="tx.KernelExcess">
                              {{shortString(tx.KernelExcess,1)}}
                              <font-awesome-icon icon='check-circle' v-if="copied===index+100" size="xs"/>
                              <font-awesome-icon icon='copy' size="xs" v-else/>
                            </span>
                      </td>
                        <td @click="copySlateID(index, tx.TxSlateID)" >
                            <span :data-tooltip="tx.TxSlateID" v-if="tx.TxSlateID">
                              {{shortString(tx.TxSlateID,1)}}
                              <font-awesome-icon icon='check-circle' v-if="copied===index" size="xs"/>
                              <font-awesome-icon icon='copy' size="xs" v-else/>
                            </span>
                        </td>
                        <td><span style="color: deepskyblue">-</span>{{tx.Amount}} {{coin}}</td>
                        <td v-if="tx.Status === 'waiting to finalize'">
                          <span :data-tooltip="tip1" @click="copySlateID(index+200, tx.S1)">
                            复制S1
                            <font-awesome-icon icon='check-circle' v-if="copied===index+200" size="xs"/>
                            <font-awesome-icon icon='copy' size="xs" v-else/>
                          </span>
                          <br>
                          <button :data-tooltip="tip2" @click="pasteS2(tx.TxSlateID)">完结交易</button>
                        </td>
                        <td v-else>
                          {{tx.Status}}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="columns is-mobile is-centered" v-show="!txs">
                    <div class="column is-narrow">
                        <div class="notification is-warning has-text" style="margin:75px">
                            <h4 class="title is-size-4 is-size-6-mobile">{{$t("msg.withdraw.noTxs")}}</h4>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        </div>
    </section>

</template>

<script>

    import {
        grinWithdrawFee,
        usdtWithdrawFee,
        minWithdrawAmount,
        mwcWithdrawFee,
        maxWithdrawGrinAmount, maxWithdrawMWCAmount, maxWithdrawUSDTAmount
    } from "../config";
    import {
        bindAddress,
        getWithdraw,
        resetAddress,
        submitWithdraw,
        validGrinTorAddress,
        validHttpAddress,
        validMWCMQSAddress,
        validTRC20Address,
        submitFinalize,
        syncAddress
    } from "../libs/api";

    const moment = require('moment')

    export default {
        name: 'Withdraw',
        components: {},
        data() {
            return {
                coin: '',
                copied: '---',
                TxSlateID: '',
                S2: '',
                memo: 0,
                address: '',
                googleAuth: true,
                googleAuthCode:'',
                addressValid: false,
                balance: 0,
                amount: 0,
                maxAmount:0,
                fee: 0,
                addressToBind: '',
                min: minWithdrawAmount,
                method: '',
                txs: null,
                tip1:this.$t('msg.withdraw.responseSlate'),
                tip2:this.$t('msg.withdraw.finalize')
            }
        },

        computed: {
            canWithdraw() {
                return (this.balance > minWithdrawAmount) &&
                    ((this.amount + this.fee) <= this.balance) &&
                    (this.amount >= minWithdrawAmount) && ((this.amount <= this.maxAmount) || (this.amount === 8000))
                    && this.address && this.amount && this.addressValid && this.googleAuth && (this.googleAuthCode.length === 6)
            },
        },

        methods: {
            handleError(err) {
                let msg = ''
                switch (err) {
                    case 'param_error':
                        msg = this.$t('msg.errors.ParamsErr')
                        break
                    case 'bad_request':
                        msg = this.$t('msg.errors.BadRequest')
                        break
                    case 'database_error':
                        msg = this.$t('msg.errors.DatabaseError')
                        break
                    case 'email_or_password_wrong':
                        msg = this.$t('msg.errors.UserInfoError')
                        break
                    case 'account_need_activate':
                        msg = this.$t('msg.errors.AccountNeedActivateError')
                        break
                    case 'send_email_failed':
                        msg = this.$t('msg.errors.SendEmailError')
                        break
                    case 'activate_account_failed':
                        msg = this.$t('msg.errors.ActivateAccountError')
                        break
                    case 'activate_address_failed':
                        msg = this.$t('msg.errors.ActivateAddressError')
                        break
                    case 'unexpected_error':
                        msg = this.$t('msg.errors.UnexpectedError')
                        break
                    case 'address_need_activate':
                        msg = this.$t('msg.errors.AddressInvalidError')
                        break
                    case 'withdraw_close':
                        msg = this.$t('msg.errors.WithdrawCloseError')
                        break
                    case 'withdraw_amount_wrong':
                        msg = this.$t('msg.errors.WithdrawAmountCloseError')
                        break
                    case 'google_code_wrong':
                        msg = this.$t('msg.errors.GAuthCodeError')
                        break
                    case 'token_wrong':
                        msg = this.$t('msg.errors.TokenError')
                        break
                    case 'coin_wrong':
                        msg = this.$t('msg.errors.CoinError')
                        break
                    case 'google_auth_not_bind':
                        msg = this.$t('msg.errors.GAuthBindError')
                        break
                    case 'email_wrong':
                        msg = this.$t('msg.errors.EmailError')
                        break
                    case 'bind_address_error':
                        msg = this.$t('msg.errors.BindAddressError')
                        break
                    case 'bind_google_auth_error':
                        msg = this.$t('msg.errors.BindGAuthError')
                        break
                    case 'google_auth_bound_error':
                        msg = this.$t('msg.errors.GAuthBoundError')
                        break
                    case 'last_withdraw_not_finish':
                        msg = this.$t('msg.errors.WithdrawNotFinishedError')
                        break
                    case 'balance_not_enough':
                        msg = this.$t('msg.errors.BalanceNotEnoughError')
                        break
                    case 'tx_not_matched':
                        msg = this.$t('msg.errors.TxNotMatchedError')
                        break
                    case 'tx_not_exists':
                        msg = this.$t('msg.errors.TxNotExistsError')
                        break
                    case 'try_again_later':
                        msg = this.$t('msg.errors.TryLaterError')
                        break
                    default:
                        msg = this.$t('msg.errors.UnexpectedError')
                        break
                }

                return msg
            },
            copySlateID(index, tx_slate_id) {
                console.log('copying')
                this.$copyText(tx_slate_id).then(() => {
                    this.copied = index
                })
            },
            pasteS2(TxSlateID){
              navigator.clipboard.readText()
                  .then(text => {
                    this.S2 = text
                  })
                  .catch(err => {
                    alert("Failed to read clipboard contents:"+err)
                  });
              this.TxSlateID = TxSlateID
            },
            async finalize() {
              const ok = confirm(this.$t('msg.withdraw.finalizeConfirmed1') + this.S2 + this.$t('msg.withdraw.finalizeConfirmed2'));
              if (ok) {
                //  finalize
                let email = localStorage.getItem("email")
                let coin = localStorage.getItem("coin")
                let token = localStorage.getItem("token")

                try {
                    let resp = await submitFinalize(email, coin, this.TxSlateID, this.S2, token)
                    this.msg = resp.data.msg
                    alert(this.msg)
                    await this.$router.push("/home")
                } catch (error) {
                    let msg = this.handleError(error.response.data.err)
                    alert(msg)
                    await this.$router.push("/home")
                }
              }
            },
            shortString(longStr, showLen) {
                let maxLen = longStr.toString().length
                return longStr.toString().slice(0, showLen) + "*" + longStr.toString().slice(-showLen, maxLen)
            },
            kernelLink(kernel){
                switch (this.coin) {
                    case "MWC":
                        return 'https://explorer.mwc.mw/BlockExplorer?type=kernel&kernel=' + kernel
                    case "GRIN":
                        return "https://grinscan.net/kernel/" + kernel
                    case "USDT":
                        return "https://tronscan.org/#/transaction/" + kernel
                }
            },

            async getWithdraw_() {
                try {
                    let email = localStorage.getItem("email")
                    let coin = localStorage.getItem("coin")
                    let token = localStorage.getItem("token")

                    this.txs = null
                    this.address = ''
                    this.addressValid = false
                    this.balance = 0
                    this.copied = ''
                    this.memo = ''
                    this.amount = 0
                    this.addressToBind = ''
                    this.googleAuthCode = ''

                    let resp = await getWithdraw(email, coin, this.method, token)
                    if (resp.data) {
                        this.address = resp.data.address
                        this.addressValid = resp.data.valid
                        this.balance = resp.data.balance.toFixed(9)
                        if (resp.data.txs.length > 0) {
                            this.txs = resp.data.txs.map(b => {
                                b.CreatedAt = moment(b.CreatedAt).format('MM-DD HH:mm')
                                return b
                            })
                        } else {
                            this.txs = null
                        }
                    }
                } catch (error) {
                    if (error.response) {
                        let msg = this.handleError(error.response.data.err)
                        alert(msg)
                        if (msg === this.$t('msg.errors.GAuthBindError')) {
                            this.googleAuth = false
                            await this.$router.push("/googleAuth")
                        }
                    }
                }
            },

            validAddress(address) {

                if (this.method === 'http') {
                    return validHttpAddress(address)
                }
                if (this.method === 'tor') {
                    return validGrinTorAddress(address)
                }
                if (this.method === 'mwcmqs') {
                    return validMWCMQSAddress(address)
                }
                if (this.method === 'trc20') {
                    return validTRC20Address(address)
                }
            },

            async bindAddress_() {
                try {

                    if (!this.validAddress(this.addressToBind)) {
                        alert(this.$t('msg.errors.AddressInvalid'))
                        return
                    }

                    let email = localStorage.getItem("email")
                    let coin = localStorage.getItem("coin")
                    let token = localStorage.getItem("token")

                    if (this.method === 'tor' && this.memo !== '') {
                        this.addressToBind += ':' + this.memo.toString()
                    }

                    let resp = await bindAddress(email, coin, this.method, this.addressToBind, token)
                    if (resp.data.msg) {
                        alert(resp.data.msg)
                        await this.getWithdraw_()
                    }
                } catch (error) {
                    if (error.response) {
                        let msg = this.handleError(error.response.data.err)
                        alert(msg)
                    }
                }
            },

            async submitWithdraw_() {
                let email = localStorage.getItem("email")
                let coin = localStorage.getItem("coin")
                let token = localStorage.getItem("token")
                try {
                    let resp = await submitWithdraw(email, coin, this.method, this.amount, token, this.googleAuthCode)

                    if (resp.data.msg) {
                        alert(resp.data.msg)
                    }
                } catch (error) {
                    if (error.response) {
                        let msg = this.handleError(error.response.data.err)
                        alert(msg)
                    }
                }
                await this.getWithdraw_()
            },

            async resetAddress_() {
                try {
                    let email = localStorage.getItem("email")
                    let coin = localStorage.getItem("coin")
                    let token = localStorage.getItem("token")
                    let resp = await resetAddress(email, coin, this.method, token)
                    if (resp.data.ok) {
                        alert(resp.data.ok)
                        await this.getWithdraw_()
                    }
                } catch (error) {
                    if (error.response) {
                        this.grinAddress = ''
                    }
                }
            },



            async syncAddress_() {
              try {
                let email = localStorage.getItem("email")
                let coin = localStorage.getItem("coin")
                let token = localStorage.getItem("token")
                let resp = await syncAddress(email, coin, this.method, token)
                if (resp.data.ok) {
                  alert(this.$t('msg.errors.SyncOk'))
                }
              } catch (error) {
                // if (error.response) {
                //   this.grinAddress = ''
                // }
              }
            },

            handleAmount2(e) {
                //2位尾数
                const precision = 1
                const re = eval("/^\\d*(\\.?\\d{0," + precision + "})/g")
                e.target.value = (e.target.value.match(re)[0]) || null
            },

            withdrawAll() {
                if ((this.balance - this.fee) < 0) {
                    return 0
                }
                this.amount = parseFloat((this.balance - this.fee).toFixed(9))

                if (this.amount > this.maxAmount) {
                    this.amount = this.maxAmount
                }
            },

            changeMethod() {
                this.getWithdraw_()
            },

            bindAddress() {
                this.bindAddress_()
            },

            resetAddress() {
                this.resetAddress_()
            },


            syncAddress() {
              this.syncAddress_()
            },

            withdraw() {
                this.submitWithdraw_()
            },

        },

        created() {
            this.coin = localStorage.getItem('coin');
            if (!this.coin) {
                this.coin = "GRIN"
            }
        },

        watch: {
            coin: async function (newVal) {
                switch (newVal) {
                    case "GRIN":
                        alert(this.$t('msg.errors.WithdrawTepCloseError'))
                        this.method = localStorage.getItem('method');
                        if (!this.method || (this.method !== "http" && this.method !== "manual") ) {
                          this.method = "tor"
                        }

                        this.fee = grinWithdrawFee
                        this.maxAmount = maxWithdrawGrinAmount
                      break
                    case "MWC":
                        this.method = localStorage.getItem('method');
                        if (!this.method || (this.method !== "http" && this.method !== "manual")) {
                          this.method = "mwcmqs"
                        }

                        this.fee = mwcWithdrawFee
                        this.maxAmount = maxWithdrawMWCAmount
                        break
                    case 'USDT':
                        this.method = 'trc20'
                        this.fee = usdtWithdrawFee
                        this.maxAmount = maxWithdrawUSDTAmount
                        break
                }
                localStorage.setItem('coin', newVal);
                await this.getWithdraw_()
            },
            method:function (newVal) {
              localStorage.setItem('method',newVal);
            },
            S2:function (newVal){
              if (newVal !== "")
              this.finalize()
              this.S2 = ""
            }
        }

    }
</script>