import client from './http'



export async function queryBalance(email,coin,token) {
    return client.get('/balance/'+email+"?coin="+coin+"&token="+token)
}

export async function queryBalance2(email,tokenIn,tokenOut, token) {
    return client.get('/balance2/'+email+"?tokenIn="+tokenIn+"&tokenOut="+tokenOut+"&token="+token)
}

export async function getSummary(email,coin,token) {
    return client.get('/summary/'+email+"?coin="+coin+"&token="+token)
}

export async function getDeposit(email,coin,method,token) {
    return client.get('/deposit/'+email+"?coin="+coin+"&method="+method+"&token="+token)
}

export async function getWithdraw(email,coin,method,token) {
    return client.get('/withdraw/'+email+"?coin="+coin+"&method="+method+"&token="+token)
}

export async function activate(code,action,coin) {
    return client.get('/auth/activate/'+code+"?action="+action+"&coin="+coin)
}

export async function submitWithdraw(email, coin, method, amount,token, googleAuthCode) {
    return client.post('/withdraw'+"?token="+token,{"email":email,"coin":coin,"method":method,"amount":amount,"googleAuthCode":googleAuthCode})
}

export async function submitFinalize(email, coin, txSlateID, s2,token) {
    return client.post('/finalize'+"?token="+token,{"email":email,"coin":coin,"txSlateID":txSlateID,"s2":s2})
}

export async function bindAddress(email,coin,method,address,token) {
    return client.post('/address'+"?token="+token,{"email":email,"coin":coin,"method":method,"address":address})
}

export async function resetAddress(email,coin,method,token) {
    return client.delete('/address'+"?token="+token,{data:{"email":email,"coin":coin,"method":method}})
}

export async function syncAddress(email,coin,method,token) {
    return client.post('/sync'+"?token="+token,{"email":email,"coin":coin,"method":method})
}

export async function getGoogleAuthSecret() {
    return client.get('/auth/google/')
}

export async function bindGoogleAuthSecret(secret, email, code,token) {
    return client.post('/auth/google/'+"?token="+token, {"secret":secret,"email":email,"code":code})
}

export function validHttpAddress(address) {
    let re = new RegExp('^(https?:\\/\\/)'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i');
    return re.test(address);
}

export function validGrinTorAddress(address) {
     return address.toString().startsWith("grin") && address.toString().length === 63
}

export function validMWCMQSAddress(address) {
     return address.toString().startsWith("mwcmqs://") && address.toString().length === 61
}

export function validTRC20Address(address) {
     // return address.toString().startsWith("T") && address.toString().length === 34
     return address.toString().length > 5
}

export async function getUserStats(email,coin) {
    let walletUrl = client.defaults.baseURL
    client.defaults.baseURL = 'https://api.pool.always.vip'
    let resp = client.get('/api/stats/'+email+'?coin='+coin)
    client.defaults.baseURL = walletUrl
    return resp
}

export async function autoBill(email,coin) {
    let walletUrl = client.defaults.baseURL
    client.defaults.baseURL = 'https://api.pool.always.vip'
    let resp =  client.get('/api/auto_withdraw/'+email+'?coin='+coin)
    client.defaults.baseURL = walletUrl
    return resp
}

export async function getPriceByInAmount(poolID,tokenIn,inAmount) {
    return client.get('/swap/tickerIn?poolID='+poolID+'&tokenIn='+tokenIn+'&inAmount='+inAmount)
}

export async function getPriceByOutAmount(poolID,tokenOut,outAmount) {
    return client.get('/swap/tickerOut?poolID='+poolID+'&tokenOut='+tokenOut+'&outAmount='+outAmount)
}

export async function submitSwap(email,token,poolID,tokenIn,inAmount,tokenOut,outAmount) {
    return client.post('/swap/submit',{'poolID':poolID,'tokenIn':tokenIn,'inAmount':inAmount,'tokenOut':tokenOut,'outAmount':outAmount,'email':email,'token':token})
}

export async function addLp(email,token,poolID,tokenIn,inAmount,tokenOut,outAmount,googleAuthCode) {
    return client.post('/swap/liquidity',{"googleAuthCode":googleAuthCode,'poolID':poolID,'tokenIn':tokenIn,'inAmount':inAmount,'tokenOut':tokenOut,'outAmount':outAmount,'email':email,'token':token})
}

export async function removeLp(email,token,poolID) {
    return client.delete('/swap/liquidity',{data:{'poolID':poolID,'email':email,'token':token}})
}

export async function getLpPrice(email,token,poolID,coin,amount,) {
    return client.get('/swap/liquidityPrice?email='+email+'&token='+token+'&poolID='+poolID+'&coin='+coin+'&amount='+amount)
}
