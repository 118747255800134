<template>
    <div style="background-color: white;">
        <div class="container" style="padding:1.2rem 2rem">
            <!--                <div class="card">-->
            <header style="display:block;">
                <div class="level">
                    <div class="level-left is-hidden-mobile">
                        <div class="level-item ">
                            <figure class="image is-64x64" style="padding:5px">
                                <img src="../assets/grin_logo.png" alt="Image" v-if="pair === 'GRIN-USDT'">
                                <img src="../assets/mwc_logo.png" alt="Image" v-if="pair === 'MWC-USDT'">
                                <img src="../assets/mwc_logo.png" alt="Image" v-if="pair === 'MWC-GRIN'">
                            </figure>
                        </div>
                        <div class="level-item">
                            <h1 class="subtitle is-3 ">
                                {{pair}}
                            </h1>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="select" style="float: right;">
                            <select v-model="pair">
                                <option value="GRIN-USDT" >GRIN-USDT</option>
                                <option value="MWC-USDT" >MWC-USDT</option>
                                <option value="MWC-GRIN" selected >MWC-GRIN</option>
                            </select>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
            </header>


            <div class="card " style="padding: 1.25rem" v-if="mod === 'swap'">
                <br/>
                <div class="field is-horizontal" style="width:  fit-content;margin: auto;">
                    <div class="field-label" style="margin-right: -0.1rem;float: left;width: 40%">
                        <div class="button" style="background-color: whitesmoke;border: whitesmoke">
                            <figure class="image is-32x32" style="margin-top:-0.2rem;float: left;">
                                <img src="../assets/grin_logo.png" alt="Image" v-if="tokenIn === 'GRIN'">
                                <img src="../assets/mwc_logo.png" alt="Image" v-if="tokenIn === 'MWC'">
                                <img src="../assets/usdt_logo.png" alt="Image" v-if="tokenIn === 'USDT'">
                            </figure>
                            <span class="is-size-3" style="margin-top:-0.3rem;padding-right: 0.1rem">|</span>
                            <label class="label">{{tokenIn}}</label>
                        </div>
                    </div>
                    <div class="field-body" style="float: right;width: 60%">
                        <div class="field">
                            <div class="control">
                                <input class="input"
                                       style="background-color: whitesmoke;border: whitesmoke;text-align: right"
                                       :placeholder="this.$t('msg.swap.hintInputAmount')" @keydown="handleAmount2" type="text" v-model.number=inAmount>
                                <p class="help" style="text-align: right" @click="swapAll">{{balance}} {{tokenIn}}</p>

                            </div>
                        </div>
                    </div>
                </div>
                <br class="is-hidden-desktop">
                <br class="is-hidden-desktop">

                <div class="field is-horizontal" style="width: 5%;margin: auto;padding-bottom: 1rem;">
                    <div class="field-label" style="">
                        <font-awesome-icon icon='sync' @click="exchange"/>
                    </div>
                </div>

                <div class="field is-horizontal" style="width: fit-content;margin: auto;">
                    <div class="field-label" style="margin-right: -0.1rem;float: left;width: 40%">
                        <div class="button" style="background-color: whitesmoke;border: whitesmoke">
                            <figure class="image is-32x32" style="margin-top:-0.2rem;float: left;">
                                <img src="../assets/grin_logo.png" alt="Image" v-if="tokenOut === 'GRIN'">
                                <img src="../assets/mwc_logo.png" alt="Image" v-if="tokenOut === 'MWC'">
                                <img src="../assets/usdt_logo.png" alt="Image" v-if="tokenOut === 'USDT'">
                            </figure>
                            <span class="is-size-3" style="margin-top:-0.3rem;padding-right: 0.1rem">|</span>
                            <label class="label">{{tokenOut}}</label>
                        </div>
                    </div>
                    <div class="field-body" style="float: right;width: 60%">
                        <div class="field">
                            <div class="control">
                                <input class="input"
                                       style="background-color: whitesmoke;border: whitesmoke;text-align: right"
                                       placeholder="输入数量" @keydown="handleAmount2" type="text" v-model.number=outAmount>
                                <p class="help is-danger" style="text-align: right" v-if="slip > 0.03">滑点：{{slip}}%</p>
                                <p class="help is-danger" style="text-align: right" v-if="warning">{{warning}}</p>
                                <p class="help is-danger" style="text-align: right" v-else-if="succeed">{{succeed}}</p>
                                <p class="help" style="text-align: right" v-else>1 {{tokenIn}} = {{price}}
                                    {{tokenOut}}</p>
                                <p class="help is-success is-link " style="text-align: right;" v-if="lp"
                                   @click="addLiquidity">{{$t("msg.swap.addLiquidity")}}</p>
                                <p class="help is-success is-link " style="text-align: right;" v-if="lp"
                                   @click="removeLp">{{$t("msg.swap.removeLp")}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal" style="width: fit-content;margin: auto;padding-top: 1rem">
                    <div class="field-body">
                        <div class="field">
                            <button class="button" style="width: 100%" @click="swap" v-if='canSwap'>
                              {{$t("msg.swap.placeOrder")}}
                            </button>
                            <button class="button" style="width: 100%" v-else disabled>
                              {{$t("msg.swap.placeOrder")}}
                            </button>
                        </div>
                    </div>
                </div>

                <br/>

            </div>

            <div class="card " style="padding: 1.25rem" v-if="mod === 'addLp'">
                <br/>
                <div class="field is-horizontal" style="width:  fit-content;margin: auto;">
                    <div class="field-label" style="margin-right: -0.1rem;float: left;width: 40%">
                        <div class="button" style="background-color: whitesmoke;border: whitesmoke">
                            <figure class="image is-32x32" style="margin-top:-0.2rem;float: left;">
                                <img src="../assets/grin_logo.png" alt="Image" v-if="tokenIn === 'GRIN'">
                                <img src="../assets/mwc_logo.png" alt="Image" v-if="tokenIn === 'MWC'">
                                <img src="../assets/usdt_logo.png" alt="Image" v-if="tokenIn === 'USDT'">
                            </figure>
                            <span class="is-size-3" style="margin-top:-0.3rem;padding-right: 0.1rem">|</span>
                            <label class="label">{{tokenIn}}</label>
                        </div>
                    </div>
                    <div class="field-body" style="float: right;width: 60%">
                        <div class="field">
                            <div class="control">
                                <input class="input"
                                       style="background-color: whitesmoke;border: whitesmoke;text-align: right"
                                       :placeholder="this.$t('msg.swap.hintInputAmount')" @keydown="handleAmount2" type="text"
                                       v-model.number=inAmount>
                                <p class="help" style="text-align: right">{{$t("msg.swap.tip1")}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal" style="width: 5%;margin: auto;padding-bottom: 1rem;">
                    <div class="field-label" style="">
                        <font-awesome-icon icon='sync' @click="exchange"/>
                    </div>
                </div>

                <div class="field " style="width: fit-content;margin: auto;">
                    <div class="field-label" style="margin-right: -0.1rem;float: left;width: 40%">
                        <div class="button" style="background-color: whitesmoke;border: whitesmoke">
                            <figure class="image is-32x32" style="margin-top:-0.2rem;float: left;">
                                <img src="../assets/grin_logo.png" alt="Image" v-if="tokenOut === 'GRIN'">
                                <img src="../assets/mwc_logo.png" alt="Image" v-if="tokenOut === 'MWC'">
                                <img src="../assets/usdt_logo.png" alt="Image" v-if="tokenOut === 'USDT'">
                            </figure>
                            <span class="is-size-3" style="margin-top:-0.3rem;padding-right: 0.1rem">|</span>
                            <label class="label">{{tokenOut}}</label>
                        </div>
                    </div>
                    <div class="field-body" style="float: right;width: 60%">
                        <div class="field">
                            <div class="control">
                                <input class="input"
                                       style="background-color: whitesmoke;border: whitesmoke;text-align: right"
                                       placeholder="this.$t('msg.swap.hintInputAmount')" @keydown="handleAmount2" type="text"
                                       v-model.number=outAmount>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>

                    <div class="field-body is-horizontal" >
                        <div class="field">
                            <p class="help" style="text-align: right" @click="swapAll">{{$t("msg.swap.balances")}} {{balance}} {{tokenIn}}
                                {{balanceOut}} {{tokenOut}}</p>
                            <p class="help is-danger" style="text-align: right" v-if="warning">{{warning}}</p>
                            <p class="help is-danger" style="text-align: right" v-else-if="succeed">{{succeed}}</p>
                            <p class="help" style="text-align: right" v-else>1 {{tokenIn}} = {{price}} {{tokenOut}}</p>
                            <p class="help is-success is-link " style="text-align: right;" v-if="lp"
                               @click="backToSwap">{{$t("msg.swap.backSwap")}}</p>
                        </div>
                    </div>
                </div>

                <div class="field " style="width: fit-content;margin: auto;">
                    <div class="field-label" style="margin-right: -0.1rem;float: left;width: 40%">
                        <div class="button" style="background-color: whitesmoke;border: whitesmoke">
                            <figure class="image is-32x32" style="margin-top:-0.2rem;float: left;">
                                <img src="../assets/google_auth.png" alt="Image">
                            </figure>
                            <span class="is-size-3" style="margin-top:-0.3rem;padding-right: 0.1rem">|</span>
                            <label class="label">{{$t("msg.swap.code")}}</label>
                        </div>
                    </div>
                    <div class="field-body" style="float: right;width: 60%">
                        <div class="field">
                            <div class="control">
                                <input class="input"
                                       style="background-color: whitesmoke;border: whitesmoke;text-align: right"
                                       :placeholder="this.$t('msg.swap.hintCode')" type="text"
                                       v-model=googleAuthCode>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal" style="width: fit-content;margin: auto;padding-top: 1rem">
                    <div class="field-body">
                        <div class="field">
                            <button class="button" style="width: 100%" @click="addLp" v-if='canAddLp'>
                              {{$t("msg.swap.addLiquidity")}}
                            </button>
                            <button class="button" style="width: 100%" v-else disabled>
                              {{$t("msg.swap.addLiquidity")}}
                            </button>
                        </div>
                    </div>
                </div>

                <br/>

            </div>


        </div>
    </div>
</template>

<script>

    import {
        addLp,
        removeLp,
        getLpPrice,
        getPriceByInAmount,
        getPriceByOutAmount,
        queryBalance,
        queryBalance2,
        submitSwap,
    } from "../libs/api";

    export default {
        name: 'Swap',
        data() {
            return {
                balance: null,
                balanceOut: null,
                tokenIn: null,
                inAmount: null,
                tokenOut: null,
                outAmount: null,
                pair: null,
                price: null,
                updating: false,
                warning: null,
                succeed: null,
                lp: null,
                googleAuthCode:'',
                mod: 'swap',
                slip: 0,
            }
        },
        computed: {
            canSwap() {
                return this.inAmount <= this.balance && this.inAmount > 0 && this.outAmount > 0 && this.warning === null
            },
            canAddLp() {
                return this.inAmount <= this.balance && this.outAmount <= this.balanceOut && this.inAmount > 0 && this.outAmount > 0 && this.googleAuthCode.toString().length === 6
            },
        },
        components: {},
        methods: {
            async getPriceByInAmount_(pair, tokenIn, newVal) {
                try {
                    let resp = await getPriceByInAmount(pair, tokenIn, newVal)
                    this.outAmount = resp.data.outAmount.toFixed(6)
                    this.slip = (resp.data.slip * 100).toFixed(4)
                    this.price = resp.data.price.toFixed(6)
                    if (!this.pair?.startsWith(tokenIn)) {
                        this.price = (1 / this.price).toFixed(6)
                    }
                } catch (error) {
                    await this.$store.dispatch('logout')
                }
            },
            async getPriceByOutAmount_(pair, tokenOut, newVal) {
                try {
                    let resp = await getPriceByOutAmount(pair, tokenOut, newVal)
                    this.inAmount = resp.data.inAmount.toFixed(6)
                    this.slip = (resp.data.slip * 100).toFixed(4)
                    this.price = resp.data.price.toFixed(6)
                    if (!this.pair?.endsWith(tokenOut)) {
                        this.price = (1 / this.price).toFixed(6)
                    }
                } catch (error) {
                    if (error.response) {
                        if (error.response.data.err === 'liquidity_not_enough') {
                            this.warning = this.$t('msg.swap.lowLiquidity')
                        } else {
                            await this.$store.dispatch('logout')
                        }
                    }
                }
            },
            async getLpPrice(pair, coin, amount){
                try {
                    let email = localStorage.getItem('email');
                    let token = localStorage.getItem('token');
                    let resp = await getLpPrice(email,token,pair, coin, amount)

                    // amount == 0 说明池子里面没有流动性
                    if (resp.data.amount > 0) {
                        switch (coin) {
                            case this.tokenIn:
                                this.outAmount = resp.data.amount
                                break
                            case this.tokenOut:
                                this.inAmount = resp.data.amount
                                break
                        }
                    }

                } catch (error) {
                    if (error.response) {
                        if (error.response.data.err === 'liquidity_not_enough') {
                            this.warning = this.$t('msg.swap.lowLiquidity')
                        } else {
                            await this.$store.dispatch('logout')
                        }
                    }
                }
            },
            handleAmount2(e) {
                //2位尾数
                const precision = 1
                const re = eval("/^\\d*(\\.?\\d{0," + precision + "})/g")
                e.target.value = (e.target.value.match(re)[0]) || null
            },
            async swap() {
                let email = localStorage.getItem("email")
                let token = localStorage.getItem("token")
                try {
                    let resp = await submitSwap(email, token, this.pair, this.tokenIn, this.inAmount.toString(), this.tokenOut, this.outAmount.toString())
                    if (resp.status === 200) {
                        this.succeed = this.$t('msg.swap.orderComplete')
                        resp = await queryBalance(email, this.tokenIn, token)
                        if (resp.data.balance >= 0) {
                            this.balance = resp.data.balance
                        }
                    }
                } catch (error) {
                    if (error.response) {
                        let err = error.response.data.err
                        if (err === 'swap_failed') {
                            this.warning = this.$t('msg.swap.overSlip')
                        }
                    }
                }
            },
            async addLp() {
                let email = localStorage.getItem("email")
                let token = localStorage.getItem("token")
                try {
                    let resp = await addLp(email, token, this.pair, this.tokenIn, this.inAmount.toString(), this.tokenOut, this.outAmount.toString(),this.googleAuthCode)
                    if (resp.status === 200) {
                        this.succeed = this.$t('msg.swap.addLpOk')
                        this.googleAuthCode = ''
                        await this.queryBalance()
                    }
                } catch (error) {
                    if (error.response) {
                        if (error.response) {
                            this.warning = error.response.data.err
                            this.googleAuthCode = ''
                        }
                    }
                }
            },
            async removeLp() {
                let email = localStorage.getItem("email")
                let token = localStorage.getItem("token")
                try {
                    let resp = await removeLp(email, token, this.pair)
                    if (resp.status === 200) {
                        this.succeed = this.$t('msg.swap.removeLpOk')
                        await this.queryBalance()
                    }
                } catch (error) {
                    if (error.response) {
                        this.warning = error.response.data.err
                    }
                }
            },
            swapAll() {
                this.inAmount = this.balance
            },
            addLiquidity() {
                this.mod = 'addLp'
                this.warning = null
                this.succeed = null
                this.inAmount = 0
                this.outAmount = 0
                this.queryBalance()
            },
            backToSwap() {
                this.mod = 'swap'
                this.warning = null
                this.succeed = null
                this.inAmount = 0
                this.outAmount = 0
            },
            async queryBalance() {
                let email = localStorage.getItem("email")
                let token = localStorage.getItem("token")

                try {
                    let resp = null
                    switch (this.mod) {
                        case "swap":
                            resp = await queryBalance(email, this.tokenIn, token)
                            if (resp.data.balance >= 0) {
                                this.balance = resp.data.balance
                                this.lp = resp.data.lp
                            }
                            break
                        case "addLp":
                            resp = await queryBalance2(email, this.tokenIn,this.tokenOut, token)
                            this.balance = resp.data.balanceIn
                            this.balanceOut = resp.data.balanceOut
                    }
                } catch (error) {
                    if (error.response) {
                        alert(error.response.data.err)
                    }
                }
            },
            exchange() {
                let temp = this.tokenIn
                this.tokenIn = this.tokenOut
                this.tokenOut = temp

                temp = this.inAmount
                this.inAmount = this.outAmount
                this.outAmount = temp

                this.balance = 0
            }
        },
        created() {
            this.mod = 'swap'
            this.pair = localStorage.getItem('pair');
            if (!this.pair) {
                this.pair = "MWC-GRIN"
            }
            this.tokenIn = this.pair.toString().split("-")[0]
            this.tokenOut = this.pair.toString().split("-")[1]
            this.queryBalance()
            this.getPriceByInAmount_(this.pair, this.tokenIn, 1)
        },
        watch: {
            pair: async function (newVal) {
                localStorage.setItem('pair', newVal);
                this.tokenIn = newVal.toString().split("-")[0]
                this.tokenOut = newVal.toString().split("-")[1]
                await this.queryBalance()
                await this.getPriceByInAmount_(this.pair, this.tokenIn, 1)
            },
            inAmount: async function (newVal) {
                if (newVal > 0 && !this.updating) {
                    this.warning = null
                    this.slip = 0
                    this.succeed = null
                    this.updating = true
                    await this.queryBalance()
                    switch (this.mod) {
                        case "swap":
                            await this.getPriceByInAmount_(this.pair, this.tokenIn, newVal)
                            break
                        case "addLp":
                            await this.getLpPrice(this.pair, this.tokenIn, newVal)
                            break
                    }
                    this.updating = false
                }
            },
            outAmount: async function (newVal) {
                if (newVal > 0 && !this.updating) {
                    this.warning = null
                    this.slip = 0
                    this.succeed = null
                    this.updating = true
                    switch (this.mod) {
                        case "swap":
                            await this.getPriceByOutAmount_(this.pair, this.tokenOut, newVal)
                            break
                        case "addLp":
                            await this.getLpPrice(this.pair, this.tokenOut, newVal)
                            break
                    }
                    this.updating = false
                }
            }
        }
    }
</script>


