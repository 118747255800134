<template>
  <form>
    <div class="box">
    
      <h1 class="title" style="color:#3298dc !important">{{$t("msg.register.register")}}
        <a @click="changeForm('login')"> <span style="font-size:0.8rem;color:#3298dc !important">or {{$t("msg.register.logIn")}}</span></a>
      </h1>
      
      <div v-if="error">
        <p><span class="tag is-danger">{{$t("msg.register.error")}} {{error}}
           <button class="delete is-small" @click="error=''" type="button"></button>
           </span>
        </p><br/>
      </div>

      <div class="field">
        <label class="label">{{$t("msg.register.email")}}</label>
        <div class="control is-clearfix">
          <input type="email" autocomplete="on" name="用户名" required="required" class="input" v-model="username">
        </div>
      </div>
      
      <div class="field">
        <label class="label">{{$t("msg.register.password")}}</label>
          <div class="control is-clearfix">
            <input type="password" autocomplete="on" name="password" required="required" class="input" v-model="password"> 
          </div>
        </div>
      
      <div class="field">
        <label class="label">{{$t("msg.register.repeatPassword")}}</label>
          <div class="control is-clearfix">
            <input type="password" autocomplete="on" name="password" required="required" class="input" v-model="password2"> 
          </div>
      </div>

      <br/>
      <div class="is-flex is-justify-end">
        <button class="button is-link is-fullwidth" @click="register" type="button">{{$t("msg.register.register1")}}</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'Register',
  data () {
    return {
      username: '',
      password: '',
      password2: '',
      error: ''
    }
  },
  methods: {
    handleError(err) {
      let msg = ''
      switch (err) {
        case 'param_error':
          msg = this.$t('msg.errors.ParamsErr')
          break
        case 'bad_request':
          msg = this.$t('msg.errors.BadRequest')
          break
        case 'database_error':
          msg = this.$t('msg.errors.DatabaseError')
          break
        case 'email_or_password_wrong':
          msg = this.$t('msg.errors.UserInfoError')
          break
        case 'account_need_activate':
          msg = this.$t('msg.errors.AccountNeedActivateError')
          break
        case 'send_email_failed':
          msg = this.$t('msg.errors.SendEmailError')
          break
        case 'activate_account_failed':
          msg = this.$t('msg.errors.ActivateAccountError')
          break
        case 'activate_address_failed':
          msg = this.$t('msg.errors.ActivateAddressError')
          break
        case 'unexpected_error':
          msg = this.$t('msg.errors.UnexpectedError')
          break
        case 'address_need_activate':
          msg = this.$t('msg.errors.AddressInvalidError')
          break
        case 'withdraw_close':
          msg = this.$t('msg.errors.WithdrawCloseError')
          break
        case 'google_code_wrong':
          msg = this.$t('msg.errors.GAuthCodeError')
          break
        case 'token_wrong':
          msg = this.$t('msg.errors.TokenError')
          break
        case 'coin_wrong':
          msg = this.$t('msg.errors.CoinError')
          break
        case 'google_auth_not_bind':
          msg = this.$t('msg.errors.GAuthBindError')
          break
        case 'email_wrong':
          msg = this.$t('msg.errors.EmailError')
          break
        case 'bind_address_error':
          msg = this.$t('msg.errors.BindAddressError')
          break
        case 'bind_google_auth_error':
          msg = this.$t('msg.errors.BindGAuthError')
          break
        case 'google_auth_bound_error':
          msg = this.$t('msg.errors.GAuthBoundError')
          break
        case 'last_withdraw_not_finish':
          msg = this.$t('msg.errors.WithdrawNotFinishedError')
          break
        case 'balance_not_enough':
          msg = this.$t('msg.errors.BalanceNotEnoughError')
          break
        case 'email_existed_error':
          msg = this.$t('msg.errors.EmailExistedError')
          break
        default:
          msg = this.$t('msg.errors.UnexpectedError')
          break
      }

      return msg
    },
    async register () {
      if(!this.validate())return
      try{
        await this.$http.post('auth/register/', {
          username: this.username,
          password: this.password,
        })
        this.$messageBus.$emit('registered')
      }catch(error){
        const msg = error.response.data.err
        if(msg === 'ok'){
          this.error = this.$t('msg.register.notice')
        }else{
          this.error = this.handleError(msg)
        }
      }
    },

    changeForm(form){
      this.$messageBus.$emit('change-form', form)
    },
  
    validate(){
      
      if(!this.username.includes('@')){
        this.error = this.$t('msg.register.notice1')
        return false
      }
      if( (!this.username) || (!this.password) || (!this.password2)){
        this.error = this.$t('msg.register.notice2')
        return false
      }
      if(this.password !== this.password2){
        this.error = this.$t('msg.register.notice3')
        return false
      }
      if(this.password.length < 8){
        this.error = this.$t('msg.register.notice4')
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>
</style>
