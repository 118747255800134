<template>
  <form v-on:submit.prevent class="narrow">
    <div class="box">
      
      <div v-if="error">
        <p><span class="tag is-danger">出错了: {{error}}
           <button class="delete is-small" @click="error=''" type="button"></button>
           </span>
        </p><br/>
      </div>
      
      <div v-if="info">
        <p><span class="tag is-success" >{{info}}
           <button class="delete is-small" @click="info=''" type="button"></button>
           </span>
        </p><br/>
      </div>

      <div class="field">
        <label class="label">Email：</label>
        <br/> 
        <div class="control is-clearfix">
          <input type="text" autocomplete="on" name="用户名" required="required" class="input" v-model="email">
        </div>
      </div>
      <br/>
      <div class="is-flex is-justify-end">
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-link" type="button" @click="submitReset">
              <span class="has-text-weight-semibold">Submit</span>
            </button>
          </p>
          <p class="control"><button class="button" @click="changeForm('login')" type="button">Back</button></p>
        </div>
      </div>

    </div>
  </form>
</template>

<script>
export default {
  name: 'Reset',
  data () {
    return {
      email:'',
      error: '',
      info: ''
    }
  },
  created(){
  },
  
  methods: {
    async submitReset () {
      if(!this.validate())return

      let data = {'email': this.email}

      try{
        await this.$http.post('/auth/request_reset/', data)
        let temp = this.email
        this.info = `密码重置的链接已发送到邮箱 ${temp}`
        this.email = ''

      }catch(error){
        const msg = error.response.data['err']
        if(msg === 'email_or_password_wrong'){
          this.error = '没有找到相关用户。'
        }else if(msg === 'reset too frequently'){
          this.error = '请不要频繁提交重置密码请求'
        }else{
          this.error = msg
        }
      }  
    },
    
    changeForm(form){
      this.$messageBus.$emit('change-form', form)
    },

    validate(){
      if( (!this.email || this.email.length < 5 || !this.email.toString().includes('@'))){
        this.error = '缺少必填项'
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>
.narrow{
  width:90%
}
</style>
