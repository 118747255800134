<template>
  <div id="app" style="background-color: cornflowerblue">
    <Navbar></Navbar>
    <router-view style="margin-top: 2rem;"/>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  
  data: function () {
    return {
      formShowing: 'login',
      timer: '',
  }},
  
  async created () {
    this.timer = setInterval(()=>{
      this.$store.commit('removeExpiredErrors', 3)
      this.$store.commit('removeExpiredInfos', 3)
    }, 2000);
    // await this.$store.dispatch('updateRate')
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
  },
}
</script>

<style>
</style>
