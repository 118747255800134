export default {
    msg: {
        notice:{
          notice1:"重要公告\n" +
              "\n" +
              "各位尊敬的VIP钱包用户，您好！\n" +
              "VIP钱包于2021年8月9日上线，自上线之日起，VIP钱包便明确说明“大额资产建议提现至自己的本地钱包中，云钱包只适合作为临时存放和中转”（公告地址：https://mp.weixin.qq.com/s/zyhJNrWXcHBklxOPi1KHRQ），且VIP矿池“常见问题”及“注意事项”中也明确告知勿长期存放资产。\n" +
              "截止北京时间2024年1月12日零时，VIP钱包共有近3000位注册用户，其中2700余位用户通过VIP钱包存放过资金。大部门用户遵守了VIP钱包的使用要求，仅将VIP钱包作为临时中转钱包。但是，有极小一部分用户未能遵守“小额临时中转”的使用准则，选择了将资产长期累计在VIP钱包中。\n" +
              "针对未遵守VIP钱包使用规则的这部分用户，VIP钱包再次发出强烈呼吁和公告，请及时将资产提现至个人本地钱包或其他钱包！。目前VIP钱包仍有用户留存的长期资产近20万grin和近3000枚MWC，请及时提现！后续VIP钱包将通过收钱包资产管理手续费的方式，强制用户进行提现。\n" +
              "\n" +
              "VIP钱包\n" +
              "2024年1月12日星期五"
        },
        errors:{
            ParamsErr               :"参数错误！",
            BadRequest              :"请求错误！",
            DatabaseError           :"已注册，请直接登录！",
            EmailExistedError       :"已注册，请直接登录！",
            UserInfoError            :"用户名或密码错误！",
            AccountNeedActivateError :"请激活账户！",
            SendEmailError           :"邮件发送失败！",
            ActivateAccountError     :"激活账户失败！",
            ActivateAddressError     :"激活地址失败！",
            UnexpectedError          :"未知错误！",
            AddressInvalidError      :"请先激活地址！",
            WithdrawCloseError       :"暂时无法提现！",
            WithdrawTepCloseError    :"点击'同步地址'可将矿池收益直接支付至钱包tor提现地址，如直接支付至gate。请勿将资产存放VIP钱包超过3天！",
            SyncOk                   :"同步地址成功！后续矿池收益将直接支付至您的grin1地址（gate地址）！",
            WithdrawAmountCloseError :"提现金额错误!",
            GAuthCodeError           :"谷歌验证码错误！",
            TokenError               :"身份验证失败！",
            CoinError                :"币种错误！",
            GAuthBindError           :"请先绑定谷歌验证器！",
            EmailError               :"邮箱错误！",
            BindAddressError         :"绑定地址出错！",
            BindGAuthError           :"绑定谷歌验证器出错！",
            GAuthBoundError          :"已经绑定谷歌验证器，请勿重复绑定！",
            WithdrawNotFinishedError :"请等待上一笔提现完成！",
            BalanceNotEnoughError    :"余额不足！",
            TxNotExistsError         :"交易不存在！",
            TxNotMatchedError        :"交易信息不匹配！",
            TryLaterError            :"请稍后再试！",

            AddressInvalid :"地址错误！",
        },
        register: {
            register: "立刻注册吧!",
            register1: "注册",
            logIn: "登录",
            email: "邮箱:",
            error: "错误:",
            password: "密码:",
            repeatPassword: "重复密码:",

            notice:"注册成功，去邮箱激活账户吧！",
            notice1:"请用邮箱注册！",
            notice2:"缺少必填项！",
            notice3:"两次输入的密码不一致！",
            notice4:"密码长度至少8位！",
        },
        login: {
            error: "出错了:",
            notice:"注册成功，请登录！",
            notice1:"登录出错！",
            notice2:"缺少必填项！",
        },
        googleAuth: {
            title:"绑定谷歌验证器",
            secret:"谷歌验证器密钥:",
            code:"谷歌验证码:",
            notice:"谷歌验证器秘钥遗失无法找回或重置！",
            notice1:"绑定成功，请妥善保管秘钥！",
            notice2:"请填写Google验证码",
            notice3:"验证出错，请稍后再试",
            bind:"绑定谷歌验证器"
        },
        nav: {
            home: "首页",
            poolHome: "矿池首页(0费率)",
            register: "立刻注册",
            logIn: "登录",
            logOut: "注销",
        },
        search: {
            poolName: "Pool.always.vip",
            homeNotice: '噢哟，不错哦~',
            inputEmailText: "输入邮箱",
            seeDetail: "查看详情",
            actionTutorial1: "输入邮箱，点击查看详情",
            actionTutorial2: "点击查看详情,进入详情页面！",
            coin: "币种",
            poolHash: "矿池算力",
            netHash: "全网算力",
            dailyIncome: "预估日收益",
            stratumAddress: "挖矿地址",
            fee: "费率",

            emailError: '邮箱错误！',
        },
        tutorial: {
            mine: {
                title: "矿机接入教程",
                step1: "1、挖矿地址填写：例如grin mini填写：stratum+tcp://grin.always.vip:3344",
                step2: "2、用户名为自己邮箱，用户名和矿机名用'.'分开，例如：邮箱为grin@qq.com，矿机名：001，则：grin@qq.com.001",
                step3: "3、通过邮箱提现，因此只支持邮箱挖矿",
                example: "设置参考",
            },
            withdraw: {
                title: "提现教程",
                step1: "1、用挖矿使用的邮箱发送邮件到",
                step2: "2、主题为：矿池提现",
                step3: "3、内容为：币种，提现地址",
                step4: "4、发送邮件后即绑定地址，之后每日自动打币，如需更改地址，只需重新发送邮件绑定新地址",
                notice: "Grin仅支持slate地址， MWC仅支持MWCMQ地址",
                example: "邮件参考",
            },
            qaList: {
                title: "常见问题",
            }
        },
        pool: {
            email: "邮箱",
            return: "返回",
            accountInfo: "账户信息",
            todayIncome: "今日收益",
            toPay: "待支付",
            paid: "已支付",
            statusToPay: "待支付",
            totalIncome: "总收益",
            autoBill:"自动支付",
            autoBillOn:"已打开",
            autoBillOff:"已关闭",
            rigs: "矿工",
            allRigs: "共计: ",
            rigName: "矿工名",
            hash15m: "1小时算力",
            hash12h: "6小时算力",
            hash24h: "12小时算力",
            hashLowAlert:"最近一小时算力偏低，请注意查看矿机！",
            hashTable:"算力图",
            dailyIncome: "支付记录",
            notice: "",
            date: "日期",
            address: "收款地址",
            amount: "数量",
            hashRate:"算力",
            txSlateID: "交易ID",
            kernel: "交易核",
            status: "状态",
            currentAddress: "当前地址：",
            plsBindAddress: "请绑定VIP钱包！",
            recentPaidRecords: "最近5日无收益记录",
            recentBlocks: "最近区块",
            coin: "币种",
            time: "时间(BJ)",
            height: "高度",
            reward: "收益",
            noRecentReward: "无最近收益",
        },
        index: {
            note1: "VIP矿池开启0费率，请享受吧~",
            note2: "请勿将资产存放本网站超过三天！",
            note4: "矿池收益可直接支付至您的gate地址！",
            note3: "请妥善保管谷歌验证器，遗失无法找回！",
            userName: "用户名:",
            password: "密码:",
            logIn: "登录",
            register: "注册",
        },
        home: {
            balance: "账户余额",
            withdraw: "提现",
            deposit: "充值",
            pool: "矿池(0费率)",
            swap: "Swap",
            transactions: "交易记录",
            data: "时间",
            method: "方式",
            kernel: "交易核",
            id: "交易ID",
            amount: "数量",
            status: "状态",
            noTxs: "最近没有交易",
        },
        withdraw: {
            address: "地址",
            resetAddress:"重置地址",
            syncAddress:"同步地址",
            bind: "绑定",
            addressNeedActivate:"地址未激活,请先去邮箱激活",
            addressNeedOnline:"请确保地址在线，否则提现会失败！",
            balance: "余额:",
            hintTOR:"地址格式为：grin1n***jm",
            hintHTTP:"地址格式为：https://*****",
            hintMWCMQS:"地址格式为：mwcmqs://*****",
            hintUSDTTRC20:"填写支付宝地址",
            hintMemo:"提现至gate等交易所需填写",
            withdrawQuantity: "提现金额:",
            withdraw: "提现",
            withdrawAll: "全部提现",
            googleAuth: "谷歌验证码:",
            minWithdraw: "最小提现金额 ",
            withdrawFee: "提现手续 ",
            maxAmount: "最大提现金额  ",

            transactions: "提现记录",
            data: "时间",
            method: "方式",
            manual: "手动",
            kernel: "交易核",
            id: "交易ID",
            amount: "数量",
            status: "状态",
            noTxs: "最近没有提现记录",

            responseSlate:"复制S1，并在钱包中生成对应S2",
            finalize:"将S2复制到粘贴板，然后点击完结交易",
            finalizeConfirmed1:"从粘贴板复制的S2为:\n",
            finalizeConfirmed2:"\n确定完结交易?",
        },
        deposit: {
            deposit: "充值",
            address: "地址:",
            balance: "余额:",
            notice:"充值时小数部分必须和Memo一致否则将无法找回，整数不限制！",

            transactions: "充值记录",
            data: "时间",
            method: "方式",
            kernel: "交易核",
            id: "交易ID",
            amount: "数量",
            status: "状态",
            noTxs: "最近没有充值记录",
        },
        swap: {
            balances:"余额:",
            hintInputAmount:"输入数量",
            addLiquidity:"添加流动性",
            removeLp:"移除流动性",
            placeOrder:"下单",
            tip1:"填入一种，另一种自动计算",
            code:"验证码",
            hintCode:"请输入谷歌验证码",
            backSwap:"返回swap",
            lowLiquidity:"资金池不足",
            orderComplete:"交易完成~",
            overSlip:"超出滑点，默认滑点1%",
            addLpOk:"添加流动性成功~",
            removeLpOk:"移除流动性成功~",
        },
        footer: {
            WalletText: "钱包:",
            WalletName: "VIP 钱包",
            customerServiceEmailText: "客服邮箱：",
            QQGroupText: "QQ群：",
            customerServiceEmail: "grincalf@gmail.com",
            QQGroup: "824825520",
            DiscordText: "Discord：",
            Discord: "邀请链接",
            copyRight: " 独立运营"
        },
    }
}