import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Index from '../views/Index.vue'
import Home from '../views/Home.vue'
import Withdraw from '../views/Withdraw.vue'
import Deposit from "../views/Deposit";
import Activate from "../views/Activate";
import Swap from "../views/Swap";
import Pool from "../views/Pool";
import Forget from "../views/Forget";
import GoogleAuth from "../views/GoogleAuth";

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'index', component: Index},
  { path: '/activate/:code', name: 'activate', component: Activate, meta: { requiresAuth: false}},

  { path: '/home', name: 'home', component: Home, meta: { requiresAuth: true}},
  { path: '/withdraw', name: 'withdraw', component: Withdraw, meta: { requiresAuth: true}},
  { path: '/deposit', name: 'deposit', component: Deposit, meta: { requiresAuth: true}},
  { path: '/pool', name: 'pool', component: Pool, meta: { requiresAuth: true}},
  { path: '/forget/:code', name: 'forget', component: Forget, meta: { requiresAuth: false}},
  { path: '/swap', name: 'swap', component: Swap, meta: { requiresAuth: true}},
  { path: '/googleAuth', name: 'googleAuth', component: GoogleAuth, meta: { requiresAuth: true}},

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.logined){
      next()
      return
    }
    next('/') 
  }else{
    next() 
  }
})

export default router
