<template>
 <section class="section" style="padding-top:0.5rem;margin-top:2rem">

  <div class="container">

    <div class="card">
      <header class="card-header" style="display:block">
        <div class="level" style="padding:17.5px 15px 17.5px 10px">
          <div class="level-left">
            <div class="level-item">
              <figure class="image is-48x48" style="padding:5px; margin-left:5px">
              <img src="../assets/grin_logo.png" alt="Image">
              </figure>
            </div>
            <div class="level-item">
              <span class="subtitle" style="font-size:1.4rem">
                <strong> {{$t('msg.googleAuth.title')}} </strong>
              </span>
            </div>
          </div>
        </div>
      </header>

      <div class="notification is-success small" v-if="info">
        <p>{{info}}</p>
      </div>

      <div class="notification is-warning small" v-if="error">
        <p>{{error}}</p>
      </div>


      <div class="card-content">
        <div class="field is-horizontal"  v-if="secret">
          <div class="field-label is-normal">
            <label class="label">{{$t('msg.googleAuth.secret')}}</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <p @click="copySlateID(1010, secret)">
                            <span :data-tooltip="secret" v-if="secret" class="help is-success is-size-1-desktop is-size-4-mobile">
                              {{secret}}
                              <font-awesome-icon icon='check-circle' v-if="copied===1010" size="xs"/>
                              <font-awesome-icon icon='copy' size="xs" v-else/>
                            </span>
                </p>
              </div>
              <p class="help" style="color: firebrick">{{$t('msg.googleAuth.notice')}}</p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal" v-if="!finished">
          <div class="field-label is-normal">
            <label class="label">{{$t('msg.googleAuth.code')}}</label>
          </div>
          <div class="field-body">

            <div class="field">
              <div class="control">
                <input class="input" type="text" v-model=googleAuthCode>
              </div>
            </div>

          </div>
        </div>
        <br/>

        <div class="field is-horizontal" v-if="!finished">
          <div class="field-label">
            <!-- Left empty for spacing -->
          </div>

          <div class="field-body">
            <div class="field">
              <div class="control">
                <button class="button is-link" @click="bindGoogleAuth">
                  {{$t('msg.googleAuth.bind')}}
                </button>
              </div>
            </div>
          </div>
        </div>

        <br/>
      </div>
    </div>

  </div>

</section>
</template>

<script>
  import {getGoogleAuthSecret, bindGoogleAuthSecret} from '../libs/api';

export default {
  name: 'GoogleAuth',
  components: {
  },
  data() {
    return {
      secret: 'sdfgh',
      googleAuthCode:'',
      copied:'',
      error: '',
      info: '',
      finished: false
    }
  },
  created() {
    this.getGoogleAuthSecret_()
  },
  methods: {
    handleError(err) {
      let msg = ''
      switch (err) {
        case 'param_error':
          msg = this.$t('msg.errors.ParamsErr')
          break
        case 'bad_request':
          msg = this.$t('msg.errors.BadRequest')
          break
        case 'database_error':
          msg = this.$t('msg.errors.DatabaseError')
          break
        case 'email_or_password_wrong':
          msg = this.$t('msg.errors.UserInfoError')
          break
        case 'account_need_activate':
          msg = this.$t('msg.errors.AccountNeedActivateError')
          break
        case 'send_email_failed':
          msg = this.$t('msg.errors.SendEmailError')
          break
        case 'activate_account_failed':
          msg = this.$t('msg.errors.ActivateAccountError')
          break
        case 'activate_address_failed':
          msg = this.$t('msg.errors.ActivateAddressError')
          break
        case 'unexpected_error':
          msg = this.$t('msg.errors.UnexpectedError')
          break
        case 'address_need_activate':
          msg = this.$t('msg.errors.AddressInvalidError')
          break
        case 'withdraw_close':
          msg = this.$t('msg.errors.WithdrawCloseError')
          break
        case 'google_code_wrong':
          msg = this.$t('msg.errors.GAuthCodeError')
          break
        case 'token_wrong':
          msg = this.$t('msg.errors.TokenError')
          break
        case 'coin_wrong':
          msg = this.$t('msg.errors.CoinError')
          break
        case 'google_auth_not_bind':
          msg = this.$t('msg.errors.GAuthBindError')
          break
        case 'email_wrong':
          msg = this.$t('msg.errors.EmailError')
          break
        case 'bind_address_error':
          msg = this.$t('msg.errors.BindAddressError')
          break
        case 'bind_google_auth_error':
          msg = this.$t('msg.errors.BindGAuthError')
          break
        case 'google_auth_bound_error':
          msg = this.$t('msg.errors.GAuthBoundError')
          break
        case 'last_withdraw_not_finish':
          msg = this.$t('msg.errors.WithdrawNotFinishedError')
          break
        case 'balance_not_enough':
          msg = this.$t('msg.errors.BalanceNotEnoughError')
          break
        default:
          msg = this.$t('msg.errors.UnexpectedError')
          break
      }

      return msg
    },
    copySlateID(index, tx_slate_id) {
      console.log('copying')
      this.$copyText(tx_slate_id).then(() => {
        this.copied = index
      })
    },
    checkForm() {
      this.error = ''
      this.googleAuthCode = this.googleAuthCode.trim()
      if (!this.googleAuthCode) {
        this.error = this.$t('msg.googleAuth.notice2')
        return false
      }
      return true
    },
    async bindGoogleAuth() {
      if (this.checkForm()) {
        try {
          let email = localStorage.getItem('email');
          let token = localStorage.getItem('token');
          let resp = await bindGoogleAuthSecret(this.secret, email, this.googleAuthCode,token)
          if (resp.data.msg === 'ok') {
              this.info = this.$t('msg.googleAuth.notice1')
              this.finished = true
          } else {
            this.error = this.$t('msg.googleAuth.notice3')
          }
        } catch (error) {
          if (error.response) {
            this.error = this.handleError(error.response.data.err)
          }
        }
      }
    },
    async getGoogleAuthSecret_() {
      try{
        let resp = await getGoogleAuthSecret()
        this.secret = resp.data.googleAuthSecret
      }catch (error){
        // await this.$store.dispatch('logout')
      }
    },
  },
}
</script>
