<template>
  <div>
    <section class="hero is-link"> 
      <div class="hero-body has-margin-top-md has-margin-bottom-md"> 
        <div class="container"> 
          <div class="columns is-centered">
            <div class="notification is-warning has-text" style="margin:75px">
              <h4 class="title is-size-4 is-size-6-mobile" >激活</h4>
            </div>
          </div>  
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import {activate} from "../libs/api"

export default {
  name: 'Activate',
  data() {
    return {
      code: '',
      action:'',
      coin:'',
    }
  },
  created() {
    this.code = this.$route.path.split('/')[2]
    this.action = this.$route.query.action
    this.coin = this.$route.query.coin
    this.activate_(this.code,this.action,this.coin)
  },
  methods:{
    async activate_(code,action,coin) {
      try{
        let resp = await activate(code,action,coin)
        if(resp.status === 200){
          alert("激活成功！")
          await this.$router.push("/home")
        }
      }catch(error){
        alert("激活失败！")
        await this.$router.push("/")
      }
    },

  }
}
</script>

<style scoped>
</style>