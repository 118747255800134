<template>
  <div style="background-color: white;" >

    <div class="container">
<!--      <div class="card">-->
<!--        <header class="card-header" style="display:block">-->
          <div class="level" style="padding:17.5px 15px 17.5px 10px">
            <div class="level-left is-hidden-mobile">
              <div class="level-item ">
                <figure class="image is-64x64" style="padding:5px">
                  <img src="../assets/grin_logo.png" alt="Image" v-if="coin === 'GRIN'">
                  <img src="../assets/mwc_logo.png" alt="Image" v-if="coin === 'MWC'">
                  <img src="../assets/usdt_logo.png" alt="Image" v-if="coin === 'USDT'">
                </figure>
              </div>
              <div class="level-item">
                <h1 class="subtitle is-3 ">
                  {{coin}}
                </h1>
              </div>
            </div>
            <div class="level-right">
              <div style="float: left" class="is-inline is-hidden-desktop">
                <div>
                  <figure class="image is-32x32" style="padding:5px;float: left;">
                    <img src="../assets/grin_logo.png" alt="Image" v-if="coin === 'GRIN'">
                    <img src="../assets/mwc_logo.png" alt="Image" v-if="coin === 'MWC'">
                    <img src="../assets/usdt_logo.png" alt="Image" v-if="coin === 'USDT'">
                  </figure>
                  <strong style="float: right;padding-top: 0.2rem"> {{$t("msg.withdraw.method")}} </strong>
                </div>
              </div>
              <div class="select" style="float: right;">
                <select v-model="coin">
                  <option value="GRIN" selected>GRIN</option>
                  <option value="MWC">MWC</option>
<!--                  <option value="USDT">USDT</option>-->
                </select>
              </div>
            </div>
          </div>
<!--        </header>-->

        <div class="card-content">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{$t("msg.withdraw.method")}}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <div class="select">
                    <select v-model="method" @change="changeMethod">
                      <option value="http" v-if="coin === 'GRIN' || coin === 'MWC'">http</option>
                      <option value="tor" v-if="coin === 'GRIN'">tor</option>
                      <option value="mwcmqs" v-if="coin === 'MWC'">MWCMQS</option>
                      <option value="trc20" v-if="coin === 'USDT'">TRC20</option>
<!--                      <option value="manual">手动</option>-->
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{coin}} {{$t("msg.withdraw.address")}}</label>
            </div>
            <div class="field-body" v-if="address">
              <div class="field">
                <div class="control">
                  <p class="help is-size-6 has-text-weight-semibold" style="word-break: break-all;word-wrap: break-word">
                    <span @click="copySlateID(1000, address)" :key="1000">
                      <span :data-tooltip="'点击复制'" v-if="address">
                        {{address}}
                        <font-awesome-icon icon='check-circle' v-if="copied===1000" size="xs" />
                        <font-awesome-icon icon='copy' size="xs" v-else/>
                      </span>
                    </span>
                  </p>
                  <br>
                  <p class="help is-size-6 has-text-weight-semibold" style="word-break: break-all;word-wrap: break-word" v-if="method !== 'http'">
                    <span>Memo:</span>
                    <span @click="copySlateID(1001, memo)" :key="1001">
                      <span :data-tooltip="'点击复制'" v-if="memo">
                        {{memo}}
                        <font-awesome-icon icon='check-circle' v-if="copied===1001" size="xs" />
                        <font-awesome-icon icon='copy' size="xs" v-else/>
                      </span>
                    </span>
                  </p>
                  <p class="help has-text-danger" v-if="method !== 'http'">{{$t("msg.deposit.notice")}}</p>
                </div>
              </div>
            </div>
            <div class="field-body" v-else>
              <div class="field">
                <div class="control">
                    <h4 class="title is-size-4 is-size-6-mobile">暂无充值地址</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--      </div>-->
    </div>

    <div class="container" style="background-color: white; padding:1.2rem 2rem">
      <div class="level">
        <div class="level-left">
          <h1 class="title is-4">
            {{$t("msg.deposit.transactions")}}
          </h1>
        </div>
        <div class="level-right">
        </div>
      </div>
    </div>
    <!--    <hr style="margin:0.15rem 0"/>-->

    <div class="container" style="background-color: white; padding: 0.75rem 2rem" >
      <table class="table is-fullwidth is-striped is-hoverable is-sortable is-size-7-mobile" v-if="txs">
        <thead>
        <tr>
          <th>{{$t("msg.withdraw.data")}}</th>
          <th>{{$t("msg.withdraw.method")}}</th>
          <th>{{$t("msg.withdraw.kernel")}}</th>
          <th>{{$t("msg.withdraw.id")}}</th>
          <th>{{$t("msg.withdraw.amount")}}</th>
          <th>{{$t("msg.withdraw.status")}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(tx, index) in txs" :key='index'>
          <td>{{tx.CreatedAt}}</td>
          <td>{{tx.Method}}</td>
<!--          <td class="is-hidden-mobile"><a :href="kernelLink(tx.KernelExcess)">{{shortString(tx.KernelExcess,4)}}</a></td>-->
          <td @click="copySlateID(index+100, tx.KernelExcess)">
                            <span :data-tooltip="tx.KernelExcess" v-if="tx.KernelExcess">
                              {{shortString(tx.KernelExcess,1)}}
                              <font-awesome-icon icon='check-circle' v-if="copied===index+100" size="xs"/>
                              <font-awesome-icon icon='copy' size="xs" v-else/>
                            </span>
          </td>
          <td @click="copySlateID(index, tx.TxSlateID)" >
                <span :data-tooltip="tx.TxSlateID" v-if="tx.TxSlateID">
                  {{shortString(tx.TxSlateID,1)}}
                  <font-awesome-icon icon='check-circle' v-if="copied===index" size="xs" />
                  <font-awesome-icon icon='copy' size="xs" v-else/>
                </span>
          </td>
          <td><span style="color: red">+</span>{{tx.Amount}}  {{coin}}</td>
          <td>{{tx.Status}}</td>
        </tr>
        </tbody>
      </table>
      <div class="columns is-mobile is-centered" v-show="!txs">
        <div class="column is-narrow">
          <div class="notification is-warning has-text" style="margin:75px">
            <h4 class="title is-size-4 is-size-6-mobile">{{$t("msg.deposit.noTxs")}}</h4>
          </div>
        </div>
      </div>
      <br/>
    </div>
  </div>

</template>

<script>

import {minWithdrawAmount} from "../config";
import {getDeposit} from "../libs/api";

const moment = require('moment')

export default {
  name: 'Deposit',
  components: {
  },
  data() {
    return {
      coin: '',
      copied:'---',
      address: '',
      memo:'',
      addressToBind:'',
      min: minWithdrawAmount,
      method: 'http',
      txs:null
    }
  },

  computed: {
  },

  methods:{
    handleError(err) {
      let msg = ''
      switch (err) {
        case 'param_error':
          msg = this.$t('msg.errors.ParamsErr')
          break
        case 'bad_request':
          msg = this.$t('msg.errors.BadRequest')
          break
        case 'database_error':
          msg = this.$t('msg.errors.DatabaseError')
          break
        case 'email_or_password_wrong':
          msg = this.$t('msg.errors.UserInfoError')
          break
        case 'account_need_activate':
          msg = this.$t('msg.errors.AccountNeedActivateError')
          break
        case 'send_email_failed':
          msg = this.$t('msg.errors.SendEmailError')
          break
        case 'activate_account_failed':
          msg = this.$t('msg.errors.ActivateAccountError')
          break
        case 'activate_address_failed':
          msg = this.$t('msg.errors.ActivateAddressError')
          break
        case 'unexpected_error':
          msg = this.$t('msg.errors.UnexpectedError')
          break
        case 'address_need_activate':
          msg = this.$t('msg.errors.AddressInvalidError')
          break
        case 'withdraw_close':
          msg = this.$t('msg.errors.WithdrawCloseError')
          break
        case 'google_code_wrong':
          msg = this.$t('msg.errors.GAuthCodeError')
          break
        case 'token_wrong':
          msg = this.$t('msg.errors.TokenError')
          break
        case 'coin_wrong':
          msg = this.$t('msg.errors.CoinError')
          break
        case 'google_auth_not_bind':
          msg = this.$t('msg.errors.GAuthBindError')
          break
        case 'email_wrong':
          msg = this.$t('msg.errors.EmailError')
          break
        case 'bind_address_error':
          msg = this.$t('msg.errors.BindAddressError')
          break
        case 'bind_google_auth_error':
          msg = this.$t('msg.errors.BindGAuthError')
          break
        case 'google_auth_bound_error':
          msg = this.$t('msg.errors.GAuthBoundError')
          break
        case 'last_withdraw_not_finish':
          msg = this.$t('msg.errors.WithdrawNotFinishedError')
          break
        case 'balance_not_enough':
          msg = this.$t('msg.errors.BalanceNotEnoughError')
          break
        default:
          msg = this.$t('msg.errors.UnexpectedError')
          break
      }

      return msg
    },
    copySlateID(index, tx_slate_id){
      console.log('copying')
      this.$copyText(tx_slate_id).then(()=>{
        this.copied = index
      })
    },
    shortString(longStr, showLen){
      let maxLen = longStr.toString().length
      return longStr.toString().slice(0, showLen) + '*' + longStr.toString().slice(-showLen, maxLen)
    },
    kernelLink(kernel){
      switch (this.coin) {
        case "MWC":
          return 'https://explorer.mwc.mw/BlockExplorer?type=kernel&kernel=' + kernel
        case "GRIN":
          return "https://grinscan.net/kernel/" + kernel
        case "USDT":
          return "https://tronscan.org/#/transaction/" + kernel
      }
    },

    async getDeposit_(){
      try {
        let email = localStorage.getItem("email")
        let coin = localStorage.getItem("coin")
        let token = localStorage.getItem("token")
        this.txs = null
        this.address = ''
        let resp = await getDeposit(email,coin,this.method,token)
        if(resp.data){
          this.address = resp.data.address
          if (this.method === 'tor' || this.method === 'mwcmqs' || this.method === 'trc20') {
            this.memo = this.address.slice(this.address.indexOf(':',10)+1,this.address.length)
            this.address = this.address.slice(0,this.address.indexOf(':',10))
          }
          if(resp.data.txs.length > 0){
            this.txs = resp.data.txs.map(b=>{
              b.CreatedAt = moment(b.CreatedAt).format('MM-DD HH:mm')
              b.Amount = b.Amount.toFixed(2)
              return b
            })
          }
        }
      }catch (error) {
        if(error.response) {
          this.error = this.handleError(error.response.data.err)
        }
      }
    },

    changeMethod(){
      this.getDeposit_()
    },
  },

  created(){
    this.coin = localStorage.getItem('coin');
    if (!this.coin || this.coin === 'USDT') {
      this.coin = "GRIN"
    }
  },
  watch: {
    coin: async function (newVal) {
      switch (newVal) {
      case 'MWC':
        this.method = 'mwcmqs'
        break
      case 'GRIN':
        this.method = 'tor'
        break
      case 'USDT':
        this.method = 'trc20'
        break
      }

      localStorage.setItem('coin', newVal);
      await this.getDeposit_()
    }
  }


}
</script>