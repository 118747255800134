<template>
    <section class="hero is-link" style="background-color: white;">
      <div class="hero-body has-margin-top-md has-margin-bottom-md">
        <div class="container">
          <div class="columns is-centered">
            <div class="notification is-warning has-text" style="margin:75px">
              <form>
                <div class="box">
                  <h1 class="title is-12 has-text-link">重置密码</h1>

                  <div v-if="error">
                    <p><span class="tag is-danger">出错了: {{error}}
           <button class="delete is-small" @click="error=''" type="button"></button>
           </span>
                    </p><br/>
                  </div>

                  <div v-if="info">
                    <p><span class="tag is-success" >{{info}}
           <button class="delete is-small" @click="info=''" type="button"></button>
           </span>
                    </p><br/>
                  </div>

                  <div class="field">
                    <label class="label">密码：</label>
                    <div class="control is-clearfix">
                      <input type="password" autocomplete="on" name="password" required="required" class="input" v-model="password">
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">重复密码：</label>
                    <div class="control is-clearfix">
                      <input type="password" autocomplete="on" name="password" required="required" class="input" v-model="password2">
                    </div>
                  </div>

                  <br/>
                  <div class="is-flex is-justify-end">
                    <div class="field is-grouped">
                      <p class="control">
                        <button class="button is-link" type="button" @click="reset">
                          <span class="has-text-weight-semibold">重置</span>
                        </button>
                      </p>
                      <p class="control"><button class="button" @click="back" type="button">首页</button></p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  name: 'Forget',
  data () {
    return {
      password: '',
      password2: '',
      code: '',
      error: '',
      info: ''
    }
  },
  created(){
    this.code = this.$route.path.split('/')[2]
    this.action = this.$route.query.action
  },
  methods: {
    back(){
      this.$router.push('/')
    },

    async reset() {
      if(!this.validate())return
      //console.log(this.code)
      let data = {
        'code': this.code,
        'action': this.action,
        'password': this.password,
      }

      try{
        let result = await this.$http.post('/auth/reset_password/', data)
        console.log(result.data)
        if(result.data.msg === 'ok'){
          this.info = '重置密码成功，请用新密码登录.'
          this.password = ''
          this.password2 = ''
        }
      }catch(error){
        const msg = error.response.data['err']
        if(msg === 'reset_password_failed'){
          this.error = '重置密码失败。'
        }else{
          this.error = msg
        }
      }
    },
    validate(){
      if( (!this.password) || (!this.password2)){
        this.error = '缺少必填项'
        return false
      }
      if(this.password !== this.password2){
        this.error = '两次输入的密码不一致'
        return false
      }
      if(this.password.length < 8){
        this.error = '密码长度需要等于或大于8'
        return false
      }
      return true
    }
  }
}
</script>

