<template>
  <section>
    <div style="background-color:white">
      <div class="container" style="padding:2rem;">
        <div class="level">
          <div class="level-left is-hidden-mobile">
            <div class="level-item ">
              <figure class="image is-64x64" style="padding:5px">
              <img src="../assets/grin_logo.png" alt="Image" v-if="coin === 'GRIN'">
              <img src="../assets/mwc_logo.png" alt="Image" v-if="coin === 'MWC'">
              <img src="../assets/usdt_logo.png" alt="Image" v-if="coin === 'USDT'">
            </figure>
            </div>
            <div class="level-item">
              <h1 class="subtitle is-3 ">
                {{coin}}
              </h1>
            </div>
          </div>
          <div class="level-right">
            <div style="float: left" class="is-hidden-desktop">
              <figure class="image is-32x32" style="padding:5px">
                <img src="../assets/grin_logo.png" alt="Image" v-if="coin === 'GRIN'">
                <img src="../assets/mwc_logo.png" alt="Image" v-if="coin === 'MWC'">
                <img src="../assets/usdt_logo.png" alt="Image" v-if="coin === 'USDT'">
              </figure>
            </div>
            <div class="select" style="float: right;">
              <select v-model="coin">
                <option value="GRIN" selected>GRIN</option>
                <option value="MWC">MWC</option>
                <option value="USDT">USDT</option>
              </select>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div class="columns is-multiline">
          <div class="column is-12-tablet is-12-desktop is-12-widescreen">
            <div class="notification has-text">
              <p class="subtitle is-6">{{$t("msg.home.balance")}}</p>
              <div class="level is-mobile" style="margin-top:-36px">
                <div class="level-left" v-if="balance != null">
                  <p class="title is-6" style="margin-top:16px">{{ balance.toFixed(3) }} {{coin}} </p>
                </div>

                <div class="level-right">
                  <button @click="withdraw" class="button is-size-4-desktop is-size-7-mobile" style="padding:0.6rem;margin-left:1.65rem">
                    {{$t("msg.home.withdraw")}}
                  </button>
                  <button @click="deposit" class="button is-size-4-desktop is-size-6-mobile"
                          style="padding:0.6rem;margin-left:0.1rem;margin-right:0.1rem">
                    {{$t("msg.home.deposit")}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="level is-mobile column is-12-tablet is-12-desktop is-12-widescreen " style="margin-top:1rem">
            <div class="level-left" style="width: 45%">
              <button @click="pool" class="button is-large" style="padding:0.6rem;width: 100%">
                {{$t("msg.home.pool")}}
              </button>
            </div>
            <div class="level-right"  style="width: 45%">
              <button @click="swap" class="button is-large" style="padding:0.6rem;width: 100%">
                {{$t("msg.home.swap")}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container" style="padding:1.2rem 2rem">
        <div class="level">
          <div class="level-left">
            <h1 class="title is-4 is-size-5-mobile">
              {{$t("msg.home.transactions")}}
            </h1>
          </div>
        </div>
      </div>
      <div class="container" style="padding: 1rem 2rem  2rem  2rem" >
        <table class="table is-fullwidth is-striped is-hoverable is-sortable is-size-7-mobile" v-if="getLength(transactions) > 0">
          <thead>
          <tr>
            <th>{{$t("msg.home.data")}}</th>
            <th>{{$t("msg.home.method")}}</th>
<!--            <th class="is-hidden-mobile">{{$t("msg.home.kernel")}}</th>-->
            <th>{{$t("msg.home.kernel")}}</th>
<!--            <th class="is-hidden-mobile">{{$t("msg.home.id")}}</th>-->
            <th>{{$t("msg.home.id")}}</th>
            <th>{{$t("msg.home.amount")}}</th>
            <th>{{$t("msg.home.status")}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(tx, index) in transactions" :key='index'>
            <td>{{tx.CreatedAt}}</td>
            <td>{{tx.Method}}</td>
<!--            <td class="is-hidden-mobile"><a :href="kernelLink(tx.KernelExcess)" >{{shortString(tx.KernelExcess,4)}}</a></td>-->
            <td @click="copySlateID(index+100, tx.KernelExcess)">
                            <span :data-tooltip="tx.KernelExcess" v-if="tx.KernelExcess">
                              {{shortString(tx.KernelExcess,1)}}
                              <font-awesome-icon icon='check-circle' v-if="copied===index+100" size="xs"/>
                              <font-awesome-icon icon='copy' size="xs" v-else/>
                            </span>
            </td>
            <td @click="copySlateID(index, tx.TxSlateID)">
                <span :data-tooltip="tx.TxSlateID" v-if="tx.TxSlateID">
                  {{shortString(tx.TxSlateID,1)}}
                  <font-awesome-icon icon='check-circle' v-if="copied===index" size="xs" />
                  <font-awesome-icon icon='copy' size="xs" v-else/>
                </span>
            </td>
            <td><span style="color: red" v-if="tx.Type === 'deposit'">+</span><span style="color: deepskyblue" v-else>-</span>{{tx.Amount}} {{coin}}</td>
            <td>{{tx.Status}}</td>
          </tr>
          </tbody>
        </table>
        <div class="columns is-mobile is-centered" v-show="getLength(transactions) === 0">
          <div class="column is-narrow">
            <div class="notification is-warning has-text" style="margin:75px">
              <h4 class="title is-size-4 is-size-6-mobile">{{$t("msg.home.noTxs")}}</h4>
            </div>
          </div>
        </div>
        <br/>
      </div>
    </div>
  </section>
</template>

<script>

import {getSummary} from '../libs/api.js'
const moment = require('moment')

export default {
  name: 'Home',
  data() {
    return {
      balance: null,
      copied: '--',
      txs: null,
      transactions: null,
      coin: null
    }
  },
  computed: {},

  methods: {
    handleError(err) {
      let msg = ''
      switch (err) {
        case 'param_error':
          msg = this.$t('msg.errors.ParamsErr')
          break
        case 'bad_request':
          msg = this.$t('msg.errors.BadRequest')
          break
        case 'database_error':
          msg = this.$t('msg.errors.DatabaseError')
          break
        case 'email_or_password_wrong':
          msg = this.$t('msg.errors.UserInfoError')
          break
        case 'account_need_activate':
          msg = this.$t('msg.errors.AccountNeedActivateError')
          break
        case 'send_email_failed':
          msg = this.$t('msg.errors.SendEmailError')
          break
        case 'activate_account_failed':
          msg = this.$t('msg.errors.ActivateAccountError')
          break
        case 'activate_address_failed':
          msg = this.$t('msg.errors.ActivateAddressError')
          break
        case 'unexpected_error':
          msg = this.$t('msg.errors.UnexpectedError')
          break
        case 'address_need_activate':
          msg = this.$t('msg.errors.AddressInvalidError')
          break
        case 'withdraw_close':
          msg = this.$t('msg.errors.WithdrawCloseError')
          break
        case 'google_code_wrong':
          msg = this.$t('msg.errors.GAuthCodeError')
          break
        case 'token_wrong':
          msg = this.$t('msg.errors.TokenError')
          break
        case 'coin_wrong':
          msg = this.$t('msg.errors.CoinError')
          break
        case 'google_auth_not_bind':
          msg = this.$t('msg.errors.GAuthBindError')
          break
        case 'email_wrong':
          msg = this.$t('msg.errors.EmailError')
          break
        case 'bind_address_error':
          msg = this.$t('msg.errors.BindAddressError')
          break
        case 'bind_google_auth_error':
          msg = this.$t('msg.errors.BindGAuthError')
          break
        case 'google_auth_bound_error':
          msg = this.$t('msg.errors.GAuthBoundError')
          break
        case 'last_withdraw_not_finish':
          msg = this.$t('msg.errors.WithdrawNotFinishedError')
          break
        case 'balance_not_enough':
          msg = this.$t('msg.errors.BalanceNotEnoughError')
          break
        default:
          msg = this.$t('msg.errors.UnexpectedError')
          break
      }

      return msg
    },
    copySlateID(index, tx_slate_id){
      console.log('copying')
      this.$copyText(tx_slate_id).then(()=>{
        this.copied = index
      })
    },
    shortString(longStr, showLen){
      let maxLen = longStr.toString().length
      return longStr.toString().slice(0, showLen) + "*" + longStr.toString().slice(-showLen, maxLen)
    },
    getLength(item){
      if (item === null) {
        return 0
      }
      return item.length
    },
    async getSummary_(coin) {
      let email = localStorage.getItem('email');
      let token = localStorage.getItem('token');
      try{
        let resp = await getSummary(email,coin,token)
        if (resp.data.balance >= 0) {
          this.balance = resp.data.balance
        }
        if(resp.data.txs.length >= 0){
          this.transactions = resp.data.txs.map(b=>{
            b.CreatedAt = moment(b.CreatedAt).format('MM-DD HH:mm')
            b.Amount = b.Amount.toFixed(2)
            return b
          })
        }
      }catch (error){
        await this.$store.dispatch('logout')
      }
    },

    withdraw() {
      this.$router.push('/withdraw')
    },

    deposit() {
      this.$router.push('/deposit')
    },

    pool() {
      this.$router.push('/pool')
    },

    swap() {
      this.$router.push('/swap')
      // alert("敬请期待～")
    },

    refresh() {
      this.getSummary_(this.coin)
    },
    kernelLink(kernel){
      switch (this.coin) {
        case "MWC":
          return 'https://explorer.mwc.mw/BlockExplorer?type=kernel&kernel=' + kernel
        case "GRIN":
          return "https://grinscan.net/kernel/" + kernel
        case "USDT":
          return "https://tronscan.org/#/transaction/" + kernel
      }
    },

  },
  created() {
    this.coin = localStorage.getItem('coin');
    if (!this.coin) {
      this.coin = "GRIN"
    }
  },

  watch: {
    coin: async function (newVal) {
      localStorage.setItem('coin', newVal);
      this.refresh()
    }
  }
}
</script>
