<template>
  <section class="section" >
    <div class="container">
      <nav class="navbar" role="navigation" aria-label="main navigation" style="background-color: cornflowerblue">
        <div class="navbar-brand">
          <a class="navbar-item" href="/">
           <h1 class="logo"><span style="color:black">Wallet.always.vip</span></h1>
          </a>
          <a role="button" class="navbar-burger burger" @click="showNav = !showNav" :class="{ 'is-active': showNav }">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div class="navbar-menu" :class="{ 'is-active': showNav }">
          <div class="navbar-start">
            <a class="navbar-item" @click="toHome">{{$t("msg.nav.home")}}</a>
            <a class="navbar-item" href="https://pool.always.vip" target="_blank">{{$t("msg.nav.poolHome")}}</a>
          </div>

          <div class="navbar-end">
            <div class="navbar-item">
              <div class="buttons" v-if="logined">
                <a class="button is-active is-small"><span class="has-text-weight-semibold" @click="logout">{{$t("msg.nav.logOut")}}</span></a>
              </div>
              <div class="buttons" v-else>
                <a class="button is-active is-small"><span  @click="changeForm('register')">{{$t("msg.nav.register")}}</span></a>
                <a class="button is-active is-small" @click="changeForm('login')">{{$t("msg.nav.logIn")}}</a>
              </div>
            </div>
            <div class="navbar-item">
                <select v-model="lang" style="padding: 0.3rem">
                  <option value="zh">简体中文</option>
                  <option value="en">English</option>
                </select>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </section>

</template>

<script>
export default {
  name: 'Navbar',
  
  data: function () {
    return {
      showNav: false,
      lang: this.$i18n.locale
    }},

  computed: {
    logined() {
      return this.$store.getters.logined
    }
  },

  methods: {
    changeForm(form){
      this.$messageBus.$emit('change-form', form)
    },
    logout(){
      this.$store.dispatch('logout')
    },
    toHome(){
      this.$router.push('/home')
      this.showNav = false
    }
  },
  watch: {
    lang:function(newVal){
      this.$i18n.locale = newVal
    }
  }
}
</script>

<style scoped>

</style>
