<template>
  <section class="section has-text-centered" style="background-color: white;">
    <div class="container" style="padding:1rem 0 1rem 0">
      <div class="container" style=" padding: 0">
        <div class="level is-mobile">
          <div class="level-left">
            <h1 class="title is-4 is-size-5-mobile">
              {{$t("msg.pool.accountInfo")}}
            </h1>
          </div>
          <div class="level-right" style="color: white">
            <div class="select" style="border-color: white">
              <select v-model="coin">
                <option value="GRIN">GRIN</option>
                <option value="MWC">MWC</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="container" style="padding: 0.2rem 0.2rem;">
        <div class="field is-horizontal">
          <div class="field-body">
            <table class="table is-fullwidth is-striped is-hoverable is-sortable is-size-7-mobile">
              <thead>
              <tr>
<!--                <th>{{$t("msg.pool.todayIncome")}}</th>-->
                <th>{{$t("msg.pool.toPay")}}</th>
<!--                <th>{{$t("msg.pool.paid")}}</th>-->
<!--                <th>{{$t("msg.pool.totalIncome")}}</th>-->
                <th>{{$t("msg.pool.autoBill")}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="assets">
<!--                <td>{{ assets[coin].todayIncome }}</td>-->
                <td>{{ assets[coin].balance }}</td>
<!--                <td>{{ assets[coin].paid }}</td>-->
<!--                <td>{{ assets[coin].total }}</td>-->
                <td v-if="assets[coin].autoBill"><span></span><button class="button" @click="bill" >{{ $t("msg.pool.autoBillOn") }}</button></td>
                <td v-else><button class="button" @click="bill" >{{ $t("msg.pool.autoBillOff") }}</button></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="container" style=" padding:0">
        <div class="level is-mobile">
          <div class="level-left">
            <h1 class="title is-4 is-size-5-mobile">
              {{$t("msg.pool.rigs")}}
            </h1>
          </div>
        </div>
      </div>
      <div class="container" style="padding: 0.2rem 0.2rem;">
        <div class="field is-horizontal">
          <div class="field-body">
            <table class="table is-fullwidth is-striped is-hoverable is-sortable is-size-7-mobile">
              <thead>
              <tr>
                <th>{{$t("msg.pool.rigName")}}</th>
                <th>{{$t("msg.pool.hash15m")}}</th>
                <th class="is-hidden-mobile">{{$t("msg.pool.hash12h")}}</th>
                <th>{{$t("msg.pool.hash24h")}}</th>
              </tr>
              </thead>
              <tbody>
                <tr v-if="getLength(rigs) > 1">
                  <td>
                    <span class="has-text-weight-semibold has-text-danger">{{OfflineRig}}</span>
                    /
                    <span class="has-text-weight-semibold has-text-success">{{rigs.length - OfflineRig}}</span>
                    /
                    <span class="has-text-weight-semibold has-text-black">{{rigs.length}}</span>
                  </td>
                  <td>{{ this.hash15m.toFixed(2) }} G/s</td>
                  <td class="is-hidden-mobile"> {{ this.hash12h.toFixed(2) }}G/s</td>
                  <td>{{ this.hash24h.toFixed(2) }} G/s</td>
                </tr>
              </tbody>

              <tbody  v-for="(s, index) in rigs" :key='index' @click="show(s)">
              <tr v-if="s.HashRateHour24*0.5 <= s.HashRateMinute15">
                <td>{{ s.Rig }}</td>
                <td>{{ s.HashRateMinute15.toFixed(2) }}</td>
                <td class="is-hidden-mobile">{{ s.HashRateHour12.toFixed(2) }}</td>
                <td>{{ s.HashRateHour24.toFixed(2) }}</td>
              </tr>
              <tr v-if="s.HashRateHour24*0.5 > s.HashRateMinute15">
                <td>{{ s.Rig }}</td>
                <td :data-tooltip='$t("msg.pool.hashLowAlert")'  style="color: #ff1b10" >{{ s.HashRateMinute15.toFixed(2) }}</td>
                <td class="is-hidden-mobile">{{ s.HashRateHour12.toFixed(2) }}</td>
                <td>{{ s.HashRateHour24.toFixed(2) }}</td>
              </tr>
              <tr v-if="showRigTable === s.Rig" aria-rowspan="3">
                <td colspan="4" style="align-content: center">
                  <div :id="s.Rig" style="height: 300px;width: 100%;">
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="container" style=" padding:0">
        <div class="level is-mobile">
          <div class="level-left">
            <h1 class="title is-4 is-size-5-mobile">
              {{$t("msg.pool.dailyIncome")}}
            </h1>
          </div>
          <div class="level-right">
            <h1 class="title is-4 is-size-7-mobile has-text-danger">
              {{$t("msg.pool.notice")}}
            </h1>
          </div>
        </div>
      </div>
      <div class="container" style=" padding:0.2rem 0.2rem">
        <table class="table is-fullwidth is-striped is-hoverable is-sortable is-size-9-mobile" v-if="getLength(incomes) > 0">
          <thead>
          <tr>
            <th>{{$t("msg.pool.date")}}</th>
            <th class="is-hidden-mobile">{{$t("msg.pool.address")}}</th>
            <th >{{$t("msg.pool.txSlateID")}}</th>
            <th >{{$t("msg.pool.kernel")}}</th>
<!--            <th>{{$t("msg.pool.hashRate")}}</th>-->
            <th>{{$t("msg.pool.amount")}}</th>
            <th>{{$t("msg.pool.status")}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(income, index) in incomes" :key='index'>
            <td>{{ income.CreatedAt.toString().split("T")[0] }}</td>
            <td class="is-hidden-mobile">{{ shortString(income.Address, 8) }}</td>
            <td @click="copySlateID(index+100, income.Hash)" >
                <span :data-tooltip="income.Hash" v-if="income.Hash">
                  {{shortString(income.Hash,1)}}
                  &nbsp;
                  <font-awesome-icon icon='check-circle' v-if="copied===index+100" size="xs" />
                  <font-awesome-icon icon='copy' size="xs" v-else/>
                </span>
            </td>
            <td @click="copySlateID(index+100, income.KernelExec)" >
                <span :data-tooltip="income.KernelExec" v-if="income.KernelExec">
                  {{shortString(income.KernelExec,1)}}
                  &nbsp;
                  <font-awesome-icon icon='check-circle' v-if="copied===index+100" size="xs" />
                  <font-awesome-icon icon='copy' size="xs" v-else/>
                </span>
            </td>
<!--            <td>{{ income.AverageHashRate.toFixed(2) }} G/s</td>-->
            <td>{{ income.Amount.toFixed(2) }}</td>
            <td>
              <p class="has-text" v-if="income.Finished">{{$t("msg.pool.paid")}}</p>
              <p class="has-text" v-else>{{$t("msg.pool.statusToPay")}}</p>
            </td>
          </tr>

          <tr class="is-hidden-mobile">
            <td>{{$t("msg.pool.currentAddress")}}</td>
            <td colspan="3">
              <p class="has-text" v-if="assets[coin].address">{{ assets[coin].address }}</p>
              <p class="has-text-danger" v-else>{{$t("msg.pool.plsBindAddress")}}</p>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="columns is-mobile is-centered" v-show="getLength(incomes) === 0">
          <div class="column is-narrow">
            <div class="notification is-warning has-text" style="margin:75px">
              <h4 class="title is-size-4 is-size-6-mobile">{{$t("msg.pool.recentPaidRecords")}}</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="container" style=" padding:0">
        <div class="level is-mobile">
          <div class="level-left">
            <h1 class="title is-4 is-size-5-mobile">
              {{$t("msg.pool.recentBlocks")}}
            </h1>
          </div>
        </div>
      </div>

      <hr style="margin:0.15rem 0"/>
      <div class="container" style=" padding:0.2rem 0.2rem">
        <table class="table is-fullwidth is-striped is-hoverable is-sortable is-size-7-mobile" v-if="getLength(rewards) > 0">
          <thead>
          <tr>
            <th>{{$t("msg.pool.coin")}}</th>
            <th>{{$t("msg.pool.time")}}</th>
            <th>{{$t("msg.pool.height")}}</th>
            <th>{{$t("msg.pool.reward")}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(reward, index) in rewards" :key='index'>
            <td>{{ coin }}</td>
            <td>{{ reward.Time.toString().slice(5,16).replace("T"," ") }}</td>
            <td>{{ reward.Height }}</td>
            <td>{{ reward.Value.toFixed(4) }}</td>
          </tr>
          </tbody>
        </table>
        <div class="columns is-mobile is-centered" v-show="getLength(rewards) === 0">
          <div class="column is-narrow">
            <div class="notification is-warning has-text" style="margin:75px">
              <h4 class="title is-size-4 is-size-6-mobile">{{$t("msg.pool.noRecentReward")}}</h4>
            </div>
          </div>
        </div>
        <br/>
      </div>

    </div>
  </section>
</template>

<script>
  import {getUserStats} from "../libs/api";
  import {autoBill} from "../libs/api";
  import * as echarts from "echarts";

  export default {
    name: 'Pool',
    data() {
      return {
        email: "",
        assets: null,
        rigs: null,
        rewards: null,
        incomes: null,
        coin: null,
        copied:'',
        hash15m:0,
        hash12h:0,
        hash24h:0,
        showRigTable:'',
        // OnlineRig:0,
        OfflineRig:0
      }
    },
    async created() {
      this.email = localStorage.getItem('email');
      this.coin = localStorage.getItem('coin');
      if (!this.coin) {
        this.coin = "GRIN"
      }
    },

    methods: {
      show(s){
        if (s.Rig === this.showRigTable) {
          this.showRigTable = ''
          return
        }
        this.showRigTable = s.Rig
        setTimeout(() => {  this.showTable(s); }, 100);
      },
      showTable(s){
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById(s.Rig));
        var option;

        let indexs = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
        const d = new Date();
        let hour = d.getUTCHours();

        for (let i = 23; i>=0; i--){
          indexs[i] = hour--
          if (hour < 0) {
            hour = 23
          }
        }

        let times = ['0:00','1:00','2:00','3:00','4:00','5:00','6:00','7:00','8:00','9:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00']
        let hashRate = [s.Hash0,s.Hash1,s.Hash2,s.Hash3,s.Hash4,s.Hash5,s.Hash6,s.Hash7,s.Hash8,s.Hash9,s.Hash10,s.Hash11,s.Hash12,s.Hash13,s.Hash14,s.Hash15,s.Hash16,s.Hash17,s.Hash18,s.Hash19,s.Hash20,s.Hash21,s.Hash22,s.Hash23]
        option = {
          xAxis: {
            name:"Time (UTC)",
            nameLocation: "center",
            type: 'category',
            nameTextStyle: {
              padding: [20, 0, 0, 0]
            },
            boundaryGap: false,
            data: [
              times[indexs[0]],times[indexs[1]],times[indexs[2]],times[indexs[3]],times[indexs[4]],
              times[indexs[5]],times[indexs[6]],times[indexs[7]],times[indexs[8]],times[indexs[9]],
              times[indexs[10]],times[indexs[11]],times[indexs[12]],times[indexs[13]],times[indexs[14]],
              times[indexs[15]],times[indexs[16]],times[indexs[17]],times[indexs[18]],times[indexs[19]],
              times[indexs[20]],times[indexs[21]],times[indexs[22]],times[indexs[23]]
            ],
          },
          title: {
            text: this.$t("msg.pool.hashTable")
          },
          yAxis: {
            name:"Rate(G/s)",
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            }
          },
          series: [
            {
              // eslint-disable-next-line no-sparse-arrays
              name:"算力",
              smooth: true,
              data: [
                hashRate[indexs[0]],hashRate[indexs[1]],hashRate[indexs[2]],hashRate[indexs[3]],hashRate[indexs[4]],
                hashRate[indexs[5]],hashRate[indexs[6]],hashRate[indexs[7]],hashRate[indexs[8]],hashRate[indexs[9]],
                hashRate[indexs[10]],hashRate[indexs[11]],hashRate[indexs[12]],hashRate[indexs[13]],hashRate[indexs[14]],
                hashRate[indexs[15]],hashRate[indexs[16]],hashRate[indexs[17]],hashRate[indexs[18]],hashRate[indexs[19]],
                hashRate[indexs[20]],hashRate[indexs[21]],hashRate[indexs[22]],hashRate[indexs[23]]
              ],
              type: 'line',
              areaStyle: {}
            }
          ]
        };
        // 绘制图表
        myChart.setOption(option)
      },
      copySlateID(index, tx_slate_id){
        console.log('copying')
        this.$copyText(tx_slate_id).then(()=>{
          this.copied = index
        })
      },
      shortString(longStr, showLen){
        let maxLen = longStr.toString().length
        return longStr.toString().slice(0, showLen) + "*" + longStr.toString().slice(-showLen, maxLen)
      },
      getLength(item){
        if (item === null) {
          return 0
        }
        return item.length
      },
      home() {
        this.$router.push('/home')
      },
      async bill() {
        let resp = await autoBill(this.email, this.coin)
        if (resp.data) {
          this.assets[this.coin].autoBill = !this.assets[this.coin].autoBill
        }
      },
      async refresh() {
        let resp = await getUserStats(this.email, this.coin)
        if (resp.data) {
          this.assets = resp.data.assets
          this.rigs = resp.data.rigs
          this.rewards = resp.data.rewards
          this.incomes = resp.data.income
        }
        this.hash15m = 0
        this.hash12h = 0
        this.hash24h = 0
        for (let i=0;i<this.rigs.length;i++){
          this.hash15m += this.rigs[i].HashRateMinute15
          this.hash12h += this.rigs[i].HashRateHour12
          this.hash24h += this.rigs[i].HashRateHour24
          if (this.rigs[i].HashRateMinute15  === 0) {
            this.OfflineRig += 1
          }
        }
      }
    },

    watch:{
      coin:async function (newVal){
        switch (newVal) {
          case 'GRIN':
            this.coin = newVal
                break
          case 'MWC':
            this.coin = newVal
                break
          default:
            this.coin = 'GRIN'
            break
        }
        localStorage.setItem('coin',newVal);
        await this.refresh()
      }
    }
  }
</script>