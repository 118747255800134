export default {
    msg: {
        notice:{
            notice1:"Important Notice\n" +
                "\n" +
                "Dear valued VIP Wallet users,\n" +
                "\n" +
                "VIP Wallet was launched on August 9, 2021. Since its launch, VIP Wallet has explicitly stated that \"large assets are advised to be withdrawn to your local wallet. Cloud wallets are only suitable for temporary storage and transfer\" (Announcement link：https://mp.weixin.qq.com/s/zyhJNrWXcHBklxOPi1KHRQ). The \"Frequently Asked Questions\" and \"Notes\" sections of VIP Mining Pool also clearly advise against long-term storage of assets.\n" +
                "\n" +
                "As of midnight on January 12, 2024, VIP Wallet has accumulated nearly 3000 registered users, with over 2700 users having deposited funds through VIP Wallet. The majority of users have followed the usage requirements, using VIP Wallet only as a temporary transfer wallet. However, a very small number of users have not adhered to the guideline of \"temporary transfer of small amounts\" and have chosen to accumulate assets in VIP Wallet over the long term.\n" +
                "\n" +
                "For those users who have not followed the rules of using VIP Wallet, VIP Wallet issues a strong appeal and announcement once again. Please withdraw your assets to your personal local wallet or another wallet promptly! Currently, VIP Wallet still holds long-term assets of nearly 200,000 GRIN and nearly 3000 MWC, which users are urged to withdraw without delay! In the future, VIP Wallet will enforce withdrawals through the collection of wallet asset management fees.\n" +
                "\n" +
                "VIP Wallet\n" +
                "Friday, January 12, 2024"
        },
        errors:{
            ParamsErr               :"Param Wrong!",
            BadRequest              :"Bad Request!",
            DatabaseError           :"Registered, pls login!",
            EmailExistedError       :"Registered, pls login!",
            UserInfoError            :"Email or Password Wrong!",
            AccountNeedActivateError :"Please Activate Account Firstly",
            SendEmailError           :"Send Email Failed",
            ActivateAccountError     :"Activate Account Failed",
            ActivateAddressError     :"Activate Address Failed",
            UnexpectedError          :"Unknown Error",
            AddressInvalidError      :"Please Activate Address Firstly",
            WithdrawCloseError       :"Withdraw Close!",
            WithdrawTepCloseError    :"Click 'Sync Address' to pay the mining pool earnings directly to the wallet tor withdrawal address, such as direct payment to gate. do not store assets in VIP wallet for more than 3 days!",
            SyncOk                   : "Synchronizing addresses was successful! Subsequent pool earnings will be paid directly to your grin1 address (gate address)!" ,
            WithdrawAmountCloseError :"Withdraw Amount Wrong",
            GAuthCodeError           :"Google Auth Code Wrong",
            TokenError               :"Token Wrong",
            CoinError                :"Coin Wrong",
            GAuthBindError           :"Please bind Google Authenticator Firstly",
            EmailError               :"Email Wrong",
            BindAddressError         :"Bind Address Failed",
            BindGAuthError           :"Bind Google Authenticator Failed",
            GAuthBoundError          :"Has Bound Google Authenticator, Please Not Try Again",
            WithdrawNotFinishedError :"Please Wait Last Withdraw Request To be Finished",
            BalanceNotEnoughError    :"Balance Not Enough",
            TxNotExistsError         :"Tx not existed",
            TxNotMatchedError        :"Tx info not match",
            TryLaterError            :"Please try again later",

            AddressInvalid :"Address Wrong",
        },
        register: {
            register: "Register Now!",
            register1: "Register",
            logIn: "Log In",
            email: "Email:",
            error: "Error:",
            password: "Password:",
            repeatPassword: "Repeat Password:",

            notice:"Register succeed！Please activate account by clicking the link sent to your email !",
            notice1:"Please input your email ！",
            notice2:"Required fields are missing！",
            notice3:"Password not match ！",
            notice4:"Length of password must more then 8 ！",
        },
        login: {
            error: "error: ",
            notice:"Sign up succeed!",
            notice1:"Log in failed ！",
            notice2:"Required fields are missing！",
        },
        googleAuth: {
            title:"Bind Google Authenticator",
            secret:"Google Authenticator Secret:",
            code:"Auth Code:",
            notice:"Please Keep Your Secret Safe ！",
            notice1:"Succeed, Please keep it safe!",
            notice2:"Please input auth code",
            notice3:"Code wrong, try later",
            bind:"Bind Google Authenticator"
        },
        nav: {
            home: "Home",
            poolHome: "Pool HomePage",
            register: "Register",
            logIn: "Log In",
            logOut: "Log Out",
        },
        search: {
            poolName: "Pool.always.vip",
            homeNotice: ' Yay!!!  New Block Found~~',
            inputEmailText: "Input Email",
            seeDetail: "See Detail",
            actionTutorial1: "Input Email，then click See Detail",
            actionTutorial2: "Click 'See Detail'",
            coin: "Coin",
            poolHash: "Pool Hash",
            netHash: "Network Hash",
            dailyIncome: "Est daily",
            stratumAddress: "Stratum Address",
            fee: "Fee",

            emailError: 'Email wrong!',
        },
        tutorial: {
            mine: {
                title: "Mine Tutorial",
                step1: "1、fill stratum address:such as stratum+tcp://grin.always.vip:3344 for grin",
                step2: "2、take your email as username, username and rig name with '.' apart, example：Your email: grin@qq.com，rig:001，so: grin@qq.com.001",
                step3: "3、set withdraw address by email, so only support email as username",
                example: "Example",
            },
            withdraw: {
                title: "Withdraw Tutorial",
                step1: "1、Send mail to customer service email using the email used for mining: ",
                step2: "2、Subject：Pool Withdraw",
                step3: "3、Content：coin and address，only support tor style address for grin(such as:grin1···ahjm), could be withdrew to Gate(Memo Needed)",
                step4: "4、send the email to customer service email,After that, the coins are automatically withdrew daily, resend email if want to change address",
                notice: "Grin only support slate address， MWC only support MWCMQ address",
                example: "Example",
            },
            qaList: {
                title: "Questions and Answers",
            }
        },
        pool: {
            email: "Email",
            return: "Back",
            accountInfo: "Account",
            todayIncome: "Today",
            toPay: "Balance",
            paid: "Paid",
            totalIncome: "Total",
            autoBillOn:"Turned On",
            autoBillOff:"Turned Off",
            autoBill:"Request Payment",
            rigs: "Rigs",
            allRigs: "All: ",
            rigName: "Name",
            hash15m: "1H",
            hash12h: "6H",
            hash24h: "12H",
            hashLowAlert:"Last hour's hash rate is low, please check the miner!",
            hashTable:"Speed Table",
            dailyIncome: "Payment records",
            notice: "",
            date: "Date",
            address: "Address",
            amount: "Amount",
            hashRate:"Hashrate",
            txSlateID: "TxSlateID",
            kernel: "kernel",
            status: "Status",
            statusPaid: "Paid",
            statusToPay: "Waiting",
            currentAddress: "Address：",
            plsBindAddress: "Please bind VIP wallet",
            recentPaidRecords: "No Payment Record",
            recentBlocks: "Recent Blocks",
            coin: "Coin",
            time: "Time(BJ)",
            height: "Height",
            reward: "Reward",
            noRecentReward: "No Recent Reward",
        },
        index: {
            note1: "VIP Mining Pool opens with 0 rate, enjoy~",
            note2: "Please do not store assets on this site for more than three days!" ,
            note4: "Mining pool earnings can be paid directly to your gate address!" ,
            note3: "Please keep your Google Authenticator safe, it can't be recovered if you lose it!" ,

            userName: "Email:",
            password: "Password:",
            logIn: "Log In",
            register: "Register",
        },
        home: {
            balance: "Balance",
            withdraw: "Withdraw",
            deposit: "Deposit",
            pool: "Pool(0 Fee)",
            swap: "Swap",

            transactions: "Transactions Record",
            data: "Date",
            method: "Method",
            kernel: "Kernel",
            id: "Slate ID",
            amount: "Amount",
            status: "Status",
            noTxs: "No Recent Transaction",
        },
        withdraw: {
            address: "Address:",
            resetAddress:"Reset Address",
            syncAddress:"Sync Address",
            bind: "Bind",
            addressNeedActivate:"Address need activate, please check email!",
            addressNeedOnline:"Please keep the address online or the withdrawal will not be successful!",
            balance: "Balance:",
            hintTOR:"Address Format: grin1n***jm",
            hintHTTP:"Address Format: https://*****",
            hintMWCMQS:"Address Format: mwcmqs://*****",
            hintUSDTTRC20:"Input Alipay account",
            hintMemo:"need for withdrawing to gate etc ",
            withdrawQuantity: "Amount:",
            withdraw: "Withdraw",
            withdrawAll: "Withdraw All",
            googleAuth: "Auth Code:",
            minWithdraw: "Min withdraw amount ",
            withdrawFee: "Withdraw Fee ",
            maxAmount: "Max Withdraw Amount ",

            transactions: "Withdraw Records",
            data: "Date",
            method: "Method",
            manual: "Manual",
            kernel: "Kernel",
            id: "Slate ID",
            amount: "Amount",
            status: "Status",
            noTxs: "No Recent Withdraw Transaction",

            responseSlate:"Copy S1 and generate response S2 in the wallet",
            finalize:"Copy S2 to the pasteboard, then click to finalize the transaction",
            finalizeConfirmed1:"S2 :\n",
            finalizeConfirmed2:"\nFinalize this transaction ?",
        },
        deposit: {
            deposit: "Dposit",
            address: "Address",
            balance: "Balance:",
            notice:"When depositing, the decimal part must be consistent with Memo or you may loss it., and the integer is not limited !",

            transactions: "Deposit Records",
            data: "Date",
            method: "Method",
            manual: "File",
            kernel: "Kernel",
            id: "Slate ID",
            amount: "Amount",
            status: "Status",
            noTxs: "No Recent Deposit Transaction",
        },
        swap: {
            balances:"Balances:",
            hintInputAmount:"Input amount",
            addLiquidity:"Add liquidity",
            removeLp:"Remove liquidity",
            placeOrder:"Order",
            tip1:"Input one side, auto get the other side",
            code:"Code",
            hintCode:"Pls input GAuth code",
            backSwap:"Back to Swap",
            lowLiquidity:"Pool insufficient fund",
            orderComplete:"Order completed",
            overSlip:"slip over 1%, stopped",
            addLpOk:"Add lp done",
            removeLpOk:"Remove lp done",
        },
        footer: {
            WalletText: "Wallet:",
            WalletName: "VIP Wallet",
            customerServiceEmailText: "Email us:",
            QQGroupText: "QQ GROUP:",
            customerServiceEmail: "grincalf@gmail.com",
            QQGroup: "824825520",
            DiscordText: "Discord:",
            Discord: "Invite Link",
            copyRight: " Independent operation"
        },


    }
}