import axios from "axios"
import store from '../store'
import {baseURL} from '@/config'
axios.defaults.baseURL = baseURL

axios.interceptors.request.use(
  config =>{
    const token = store.state.token
    if(token){
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {return Promise.reject(error)}
)

axios.interceptors.response.use(
  (response) => {return response},
  (error) => {
    if(error.status === 401 || error.status === 400){
      console.log('error status' + error.status)
      store.dispatch('logout')}
    return Promise.reject(error)
    }
)
export default axios