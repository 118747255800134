<template>
  <section class="hero section" style="background-color: white">
      <div class="hero-body has-margin-top-md has-margin-bottom-md">
        <div class="container"> 
          <div class="columns is-vcentered">
            <div class="column is-7 content is-medium">
              <h1 class="title is-2 is-spaced is-size-3-mobile">Wallet.
                <span class="logo is-size-3-mobile">always.vip</span>
              </h1>
              <ul>
                <li>{{$t("msg.index.note1")}}</li>
                <li>{{$t("msg.index.note2")}}</li>
                <li>{{$t("msg.index.note4")}}</li>
                <li>{{$t("msg.index.note3")}}</li>
              </ul>
            </div>

            <div class="column is-4 is-offset-1" v-show="formShowing === 'login'">
              <div class="column is-narrow">
                <Login></Login>
              </div>
            </div>

            <div class="column is-5 is-offset-1" v-show="formShowing === 'register'">
              <div class="column is-narrow">
                <Register v-if="formShowing === 'register'"></Register>
              </div>
            </div>

            <div class="column is-5 is-offset-1" v-show="formShowing === 'reset'">
              <div class="column is-narrow">
                <Reset v-if="formShowing === 'reset'"></Reset>
              </div>
            </div>

          </div>
        </div>
      </div>
  </section>
</template>

<script>
import Login from '@/components/Login.vue'
import Register from '@/components/Register.vue'
import Reset from '@/components/Reset.vue'

export default {
  name: 'Index',
  components: {
    Login,
    Register,
    Reset
  },
  data: function () {
    return {
      formShowing: 'login'
  }},
  computed: {
    logined() {
      return this.$store.getters.logined
    },
    networkRate(){
      return this.$store.state.networkRate
    }
  },
  watch: {
    logined: function (val) {
      if(val)this.$router.push('/home')

      alert(this.$t('msg.notice.notice1'))
    }
  },
  methods: {
  },
  created(){
    this.$messageBus.$on('change-form', (form)=>{
      //console.log('change-form', form)
      this.formShowing = form
    })
    this.$messageBus.$on('registered', ()=>{
      this.formShowing = 'login'
    })
  }
}
</script>

<style scoped>
</style>
