<template>
  <section class="section">
    <footer class="footer" style="background-color: cornflowerblue">
      <div class="container">
        <div class="columns">
          <div class="column is-6">
            <div class="content">
              <p><span class="logo" style="font-size:1rem">Wallet.always.vip</span> <a class="tag is-light"> Always.vip </a>{{$t("msg.footer.copyRight")}} </p>
              <p>© 2021-2022</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
