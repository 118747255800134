export const baseURL = 'https://api.wallet.always.vip'
// export const baseURL = 'http://localhost:9002'

export const loginURL = '/auth/login/'
export const registerURL = '/auth/register/'
export const refreshURL = '/auth/refresh/'

export const canWithdraw = true
export const minWithdrawAmount = 1
export const grinWithdrawFee = 1
export const usdtWithdrawFee = 1
export const mwcWithdrawFee = 0.2
export const maxWithdrawGrinAmount = 5000
export const maxWithdrawMWCAmount = 100
export const maxWithdrawUSDTAmount = 2000

