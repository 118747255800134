<template>
  <form v-on:submit.prevent>
    <div class="box">
      
      <div v-if="error">
        <p><span class="tag is-danger">{{$t("msg.login.error")}} {{error}}
           <button class="delete is-small" @click="error=''" type="button"></button>
           </span>
        </p><br/>
      </div>
      
      <div v-if="info">
        <p><span class="tag is-success">{{info}}
           <button class="delete is-small" @click="info=''" type="button"></button>
           </span>
        </p><br/>
      </div>

      <div class="field">
        <label class="label">{{$t("msg.index.userName")}}</label>
        <div class="control is-clearfix">
          <input type="email" autocomplete="on" name="用户名" required="required" class="input" v-model="username">
        </div>
      </div>
      
      <div class="field">
        <label class="label">{{$t("msg.index.password")}}</label>
          <div class="control is-clearfix">
            <input type="password" autocomplete="on" name="password" required="required" class="input" v-model="password"> 
          </div>
        </div>
      <br/>
      <div class="is-flex is-justify-end">
        <div class="field is-grouped">
          <p class="control">
            <button class="button" type="button" @click="login">
              <span class="has-text-weight-semibold">{{$t("msg.index.logIn")}}</span>
            </button>
          </p>
          <p class="control"><button class="button" @click="changeForm('register')" type="button">{{$t("msg.index.register")}}</button></p>
          <button style="vertical-align: bottom;" class="button is-text is-small is-active" type="button" @click="changeForm('reset')">忘记密码?</button>
        </div>
      </div>

    </div>
  </form>
</template>

<script>

export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      error: '',
      info: ''
    }
  },
  created(){
    this.$messageBus.$on('registered', ()=>{
      this.info = this.$t('msg.login.notice')
    })
  },
  methods: {
    handleError(err) {
      let msg = ''
      switch (err) {
        case 'param_error':
          msg = this.$t('msg.errors.ParamsErr')
          break
        case 'bad_request':
          msg = this.$t('msg.errors.BadRequest')
          break
        case 'database_error':
          msg = this.$t('msg.errors.DatabaseError')
          break
        case 'email_or_password_wrong':
          msg = this.$t('msg.errors.UserInfoError')
          break
        case 'account_need_activate':
          msg = this.$t('msg.errors.AccountNeedActivateError')
          break
        case 'send_email_failed':
          msg = this.$t('msg.errors.SendEmailError')
          break
        case 'activate_account_failed':
          msg = this.$t('msg.errors.ActivateAccountError')
          break
        case 'activate_address_failed':
          msg = this.$t('msg.errors.ActivateAddressError')
          break
        case 'unexpected_error':
          msg = this.$t('msg.errors.UnexpectedError')
          break
        case 'address_need_activate':
          msg = this.$t('msg.errors.AddressInvalidError')
          break
        case 'withdraw_close':
          msg = this.$t('msg.errors.WithdrawCloseError')
          break
        case 'google_code_wrong':
          msg = this.$t('msg.errors.GAuthCodeError')
          break
        case 'token_wrong':
          msg = this.$t('msg.errors.TokenError')
          break
        case 'coin_wrong':
          msg = this.$t('msg.errors.CoinError')
          break
        case 'google_auth_not_bind':
          msg = this.$t('msg.errors.GAuthBindError')
          break
        case 'email_wrong':
          msg = this.$t('msg.errors.EmailError')
          break
        case 'bind_address_error':
          msg = this.$t('msg.errors.BindAddressError')
          break
        case 'bind_google_auth_error':
          msg = this.$t('msg.errors.BindGAuthError')
          break
        case 'google_auth_bound_error':
          msg = this.$t('msg.errors.GAuthBoundError')
          break
        case 'last_withdraw_not_finish':
          msg = this.$t('msg.errors.WithdrawNotFinishedError')
          break
        case 'balance_not_enough':
          msg = this.$t('msg.errors.BalanceNotEnoughError')
          break
        default:
          msg = this.$t('msg.errors.UnexpectedError')
          break
      }

      return msg
    },
    login () {
      if(!this.validate())return
      
      this.$store.dispatch('login', {
        username: this.username,
        password: this.password,
      }).catch(error=>{
        this.error = this.handleError(error.response.data.err)
      })
    },
    changeForm(form){
      this.$messageBus.$emit('change-form', form)
    },
    validate(){
      if( (!this.username) || (!this.password)){
        this.error = this.$t('msg.login.notice2')
        return false
      }
      return true
    }
  }

}
</script>

<style scoped>
</style>
