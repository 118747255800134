import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {baseURL, loginURL, refreshURL} from '@/config'
import router from '../router'

Vue.use(Vuex)

let client = axios.create({
  baseURL: baseURL
});
export default new Vuex.Store({
  state: {
    token: '',
    
    openEmail: false,
    openEmail4Verify: false,
    emailVerifyPurpose: '',
    openSlatepack: false,
    openSlatepack2: false,

    errors: [],
    infos: [],
    grinAddress: '',


    //slatepack: {id: xxx, s1: xxxx, s2 xxx}
    slatepack: null,


  },
  getters:{
    logined: state =>{
      return state.token != ''
    }
  },
  mutations: {
    updateToken(state, token){
      state.token = token
    },
    removeToken(state){
      state.token = ''
    },

    setRate(state, rate){
      state.poolRate = rate.poolRate
      state.networkRate = rate.networkRate
    },

    openEmail(state){
      state.openEmail = true
    },
    closeEmail(state){
      state.openEmail = false
    },

    openEmail4Verify(state, purpose){
      state.openEmail4Verify = true
      state.emailVerifyPurpose = purpose
    },
    closeEmail4Verify(state){
      state.openEmail4Verify= false
      state.emailVerifyPurpose = ''
    },

    addError(state, error){
      let err = {
        'createdAt': Date.now(),
        'text': error
      }
      state.errors.push(err)
    },
    removeError(state, createdAt){
      state.errors = state.errors.filter(e=>e.createdAt!==createdAt)
    },
    removeExpiredErrors(state, time){
      state.errors = state.errors.filter(e=>(Date.now()-e.createdAt) <= time*1000)
    },

    addInfo(state, info){
      let info_ = {
        'createdAt': Date.now(),
        'text': info
      }
      state.infos.push(info_)
    },
    removeInfo(state, createdAt){
      state.infos = state.infos.filter(i=>i.createdAt!==createdAt)
    },
    removeExpiredInfos(state, time){
      state.infos = state.infos.filter(i=>(Date.now()-i.createdAt) <= time*1000)
    },
    
  },
  actions: {
    login({commit}, user){
      return new Promise((resolve, reject) => {
        client.post(loginURL, user).then(resp =>{
            const token = resp.data.token
            console.log('token after login:' + token)
            commit('updateToken', token)
            localStorage.setItem('token', token)
            localStorage.setItem('email',user.username)
            resolve(resp)
          }).catch(err => {
            commit('removeToken')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    refreshToken({commit}, token){
      let email = localStorage.getItem('email')
      return new Promise((resolve, reject) => {
        client.get(refreshURL+"?email="+email+"&token="+token).then(resp =>{
            const token = resp.data.token
            console.log('token after refresh:' + token)
            commit('updateToken', token)
            localStorage.setItem('token', token)
            resolve(resp)
          }).catch(err => {
            commit('removeToken')
            localStorage.removeItem('token')
            reject(err)
        })
      })
    },
    logout({commit}){
      commit('removeToken')
      localStorage.removeItem('token')
      router.push('/')
    }
  },
  modules: {
  }
})
