import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import messages from './lang'

let lang = 'en'
if (navigator.language.includes('zh')) {
  lang = 'zh'
}

export default new VueI18n({
  locale: lang,
  messages
})